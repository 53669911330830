@use 'abstracts/functions';
@use 'abstracts/mixins/layout';
@use 'abstracts/mixins/base' as base-mixins;

$_column-break: functions.break(60);

.m-two-columns {
  @include layout.container-primary;
  @include base-mixins.fluid-scale(
    margin-block-start margin-block-end,
    functions.padding(medium),
    functions.padding(x-large)
  );

  .m-group &:last-child {
    margin-block-end: 0;
  }

  + .m-group.a-bg-white {
    padding-block-start: 0;
  }

  .wp-block-group__inner-container > &:first-child {
    margin-block-start: 0;
  }

  @media (min-width: $_column-break) {
    display: flex;
  }

  &__child {
    &--bordered {
      @include base-mixins.fluid-scale(
        padding-inline-start,
        functions.padding(x-x-small),
        functions.padding(x-small)
      );

      @media (min-width: $_column-break) {
        border-inline-start-width: 5px;
      }

      align-self: flex-start;
      border-inline-start: 3px solid functions.palette(dark-blue);
    }

    @media (min-width: $_column-break) {
      &--40 {
        flex-basis: 40%;
      }

      &--50 {
        flex-basis: 50%;
      }

      &--60 {
        flex-basis: 60%;
      }
    }

    & + & {
      @include base-mixins.fluid-scale(
        margin-inline-start,
        functions.padding(x-small),
        functions.padding(x-large)
      );
      margin-block-start: functions.padding(x-small);

      @media (max-width: $_column-break - 1) {
        margin-inline-start: 0;
      }

      @media (min-width: $_column-break) {
        margin-block-start: 0;
      }
    }
  }
}
