@use 'abstracts/functions';
@use 'abstracts/mixins/base';

$_indicator-border-size: 32px;
$_indicator-inner-size: 20px;
$_indicator-padding: 16px;

.m-radio {
  position: relative;
  display: flex;
  align-items: baseline;
  padding-inline-start: $_indicator-padding + $_indicator-border-size;
  font-size: inherit;
  line-height: 1.375;
  color: functions.palette(dark-blue);
  cursor: pointer;

  & + & {
    margin-block-start: 38px;
  }

  &__input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  &__indicator {
    position: absolute;
    top: -0.1 * $_indicator-border-size;
    left: 0;
    display: block;
    width: $_indicator-border-size;
    height: $_indicator-border-size;
    pointer-events: none;
    border: 3px solid currentColor;
    border-radius: 50%;

    .m-radio__input:focus-visible ~ & {
      box-shadow: 0 0 0 2px functions.palette(white), 0 0 0 4px currentColor;
    }

    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: $_indicator-inner-size;
      height: $_indicator-inner-size;
      content: '';
      background-color: transparent;
      border-radius: 50%;
      transform: translate(-50%, -50%);

      .m-radio__input:checked ~ & {
        background-color: currentColor;
      }
    }
  }
}
