@use 'abstracts/functions';
@use 'abstracts/mixins/base' as base;
@use 'abstracts/mixins/layout' as layout;

$_column-break: functions.break(60);

.m-post {
  &__author {
    @include base.fluid-scale(
      padding-block,
      functions.padding(x-small),
      functions.padding(medium)
    );
    @include base.fluid-scale(
      padding-inline,
      functions.padding(x-small),
      functions.padding(x-large)
    );
    margin: 0;
    background: functions.palette(seafoam);

    &--inner {
      display: flex;
      align-items: center;
      margin-inline: auto;
      max-inline-size: functions.container-width(wide);

      img {
        border-radius: 50%;
        inline-size: 60px;
        block-size: 60px;
      }

      p {
        padding: 0;
        padding-inline-start: 21px;
        margin: 0;
        text-transform: capitalize;

        &:first-child {
          font-weight: functions.font-weight(bold);
        }
      }
    }
  }

  &__inner {
    @include layout.proto-container(
      functions.container-width(max),
      functions.padding(x-large),
      functions.padding(x-small)
    );
    @include base.fluid-scale(
      padding-block,
      functions.padding(x-small),
      functions.padding(x-x-large)
    );
    margin-block-start: 0;
  }

  &__content {
    @include layout.container-wide;
    @include base.fluid-scale(padding-inline, 0px, functions.padding(x-large));
    @include base.fluid-scale(
      padding-block-end,
      functions.padding(small),
      functions.padding(x-x-large)
    );

    p {
      &:last-child {
        padding-block-end: 0;
      }
    }
  }

  &__title,
  &__date,
  &__author {
    color: functions.palette(dark-blue);
  }

  &__title {
    padding-block-end: functions.padding(large);
    font-size: 24px;
    font-weight: functions.font-weight(book);
  }

  &__navigation {
    display: flex;
    justify-content: space-between;
    padding-block-start: 21px;
    color: functions.palette(dark-blue);
    border-top: 2px solid functions.palette(dark-blue);

    svg {
      font-size: 0.8em;
    }

    a {
      font-size: 16px;
      font-weight: functions.font-weight(bold);
      color: functions.palette(dark-blue);
      text-decoration: none;
    }
  }

  .m-hero {
    margin: 0;
  }
}
