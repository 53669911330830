@use 'abstracts/functions';
@use 'abstracts/mixins/base' as base-mixins;
@use 'abstracts/mixins/layout';
@use 'abstracts/mixins/appearance';
@use 'abstracts/variables';
@use 'modules/drawer';

.m-subnav {
  inset-block-start: var(--site-header-height);
  inset-inline-start: 0;
  z-index: -5; // relative to .m-header
  inline-size: 100%;
  font-weight: functions.font-weight(book);
  text-align: start;

  ol {
    list-style-position: inside;
    list-style-type: decimal;
  }

  &.is-visible {
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
    transform: translate(0px, 0px);
  }

  &.is-hidden {
    opacity: 0;
  }

  &__inner {
    color: functions.palette(dark-blue);

    &--short {
      @include layout.container-secondary;
      padding-block-start: 22px;
      padding-block-end: 23px;
    }

    &--justify-end {
      justify-content: flex-end;
    }
  }

  &__back-button,
  &__list-left {
    font-weight: functions.font-weight(black);
  }

  &__back-button {
    display: flex;
    align-items: center;
    inline-size: 100%;
    padding-block-start: functions.padding(x-small) * 2;
    padding-block-end: 5px;
    cursor: pointer;
  }

  &__list {
    &-left {
      padding-block-start: functions.padding(x-small);
      padding-block-end: functions.padding(x-small);
    }
  }

  &__back-icon {
    position: relative;
    inset-block-start: -2px;
    margin-inline-end: 5px;
    fill: currentColor;
    transform: rotate(180deg);
  }

  &__cta {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__item {
    @include base-mixins.fluid-scale(
      padding-block-end,
      functions.padding(small),
      functions.padding(medium)
    );
  }

  &__link {
    font-weight: functions.font-weight(bold);
    text-decoration: none;
  }

  &__button {
    min-height: 36.45px;
  }

  // Mobile & desktop styles are very different,
  // so keep styles scoped by media query together
  @media (max-width: variables.$header-breakpoint - 1) {
    @include drawer.drawer;
    inset-block-start: 0px;
    background-color: functions.palette(white);

    &__list {
      border-block-end: 3px solid;

      &:not(:first-child) {
        padding-block-start: functions.padding(small);
      }
    }

    &__cta {
      padding-block-start: functions.padding(small);
    }
  }

  @media (min-width: variables.$header-breakpoint) {
    position: absolute;
    background-color: functions.palette(white);
    transform: translateY(-100%);

    &.is-visible {
      @include appearance.box-shadow;
      transform: translateY(0);
    }

    &__inner {
      display: flex;

      &--full {
        @include layout.container-primary;
        @include base-mixins.fluid-scale(
          padding-block,
          functions.padding(x-small),
          functions.padding(x-large)
        );
      }
    }

    &__back-button {
      display: none;
    }

    &__list,
    &__cta {
      flex-grow: 1;
    }

    &__list > * {
      border-inline-start: 5px solid;
    }

    &__cta {
      display: block;

      > * + * {
        padding-block-start: functions.padding(small);
      }
    }

    &__item {
      padding-inline-start: functions.padding(x-x-small);

      &:last-child {
        padding-block-end: 0;
      }
    }
  }
}
