@use 'abstracts/functions';
@use './mixins' as help-center-mixins;

.m-document-accordion {
  @include help-center-mixins.show-mobile;
  color: functions.help-center(color-primary);
  background-color: functions.palette(seafoam);

  &__summary {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding: 0.75em 0.5em 1.25em;
  }

  &__title {
    display: flex;
    align-items: center;
    font-size: inherit;
    font-weight: functions.font-weight(black);

    > * + * {
      margin-inline-start: 5px;
    }
  }

  &__body {
    padding: 1em 0.5em;
  }

  &__list {
  }
}
