@use 'abstracts/functions';
@use 'abstracts/mixins/layout';
@use 'abstracts/mixins/base' as base-mixins;
@use 'abstracts/mixins/appearance';
@use 'abstracts/variables';

$margin-lateral: functions.padding(small);
$margin-vertical: functions.padding(x-small);
$_dialog-header-height: 85px;

.m-dialog {
  @include appearance.box-shadow;
  position: fixed;
  inset-block-start: 0;
  inset-inline-start: 0;
  // Use grid to set inner height without calc
  display: grid;
  grid-template:
    'header' auto
    'inner' 1fr
    / 1fr;
  inline-size: 100%;
  max-inline-size: functions.container-width(max);
  block-size: 100vh;
  visibility: hidden;
  background-color: functions.palette(dark-blue);
  opacity: 0;

  @media (min-width: variables.$admin-bar-pos-breakpoint) {
    inset-block-start: calc(50% + var(--admin-bar-height, 0px) / 2);
    inset-inline-start: 50%;
    max-block-size: calc(100vh - #{$margin-vertical * 2});
    transform: translate(-50%, -50%);
  }

  @include base-mixins.respond(functions.break(60)) {
    max-block-size: 750px;
  }

  &.is-open {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.2s, visibility 0.2s;
  }

  &__header {
    display: flex;
    grid-area: header;
    justify-content: flex-end;
    padding: functions.padding(x-small);
    color: functions.palette(white);

    @include base-mixins.respond(functions.break(60)) {
      justify-content: space-between;
    }
  }

  &__logo {
    display: none;
    inline-size: 186.17px;
    block-size: 45.43px;
    fill: currentColor;

    @include base-mixins.respond(functions.break(60)) {
      display: block;
    }
  }

  &__close {
    cursor: pointer;
  }

  &__close-icon {
    fill: currentColor;
  }

  &__inner {
    position: relative;
    grid-area: inner;
    overflow: auto;
    background-color: functions.palette(white);
  }

  &__content {
    @include base-mixins.fluid-scale(
      padding,
      functions.padding(x-small),
      functions.padding(x-large)
    );

    @include base-mixins.respond(functions.break(60) - 1, max) {
      // account for height of iOS bottom bar
      padding-block-end: 130px;
    }
  }
}
