@use 'abstracts/functions';
@use 'abstracts/mixins/typography';
@use './mixins' as help-center-mixins;

@mixin mobile-styling {
  font-size: 24px;
  font-weight: functions.font-weight(book);
  color: functions.help-center(color-primary);
  border-block-end: 1px solid;
}

.m-help-center {
  &__page-title {
    margin-block-end: 1em;
    color: functions.help-center(color-primary);

    &--category {
      @include help-center-mixins.show-mobile;
      @include mobile-styling;
      margin-block-start: 2em;
      margin-inline: functions.padding(x-small);
    }

    &--subcategory {
      @include help-center-mixins.on-mobile {
        @include mobile-styling;
      }

      @include help-center-mixins.on-desktop {
        @include typography.heading-quaternary;
      }
    }
  }

  &__back-to-link {
    @include help-center-mixins.show-mobile;
  }

  &__show-desktop {
    @include help-center-mixins.show-desktop;
  }
}
