@use 'abstracts/functions';
@use 'abstracts/mixins/base' as base-mixins;
@use 'abstracts/mixins/layout' as layout-mixins;
@use 'abstracts/mixins/typography' as type-mixins;

@mixin footer-padding(
  $min: functions.padding(x-small),
  $max: functions.padding(large)
) {
  @include base-mixins.fluid-scale(padding-block, $min, $max);
}

$largest-footer-icon-width: 207px;
$_footer-w: functions.break(90);
$_footer-mw: functions.break(60);

.m-footer {
  @include footer-padding;
  @include type-mixins.copy-secondary;
  color: functions.palette(white);
  background-color: functions.palette(dark-blue);

  &__inner {
    @include layout-mixins.container-secondary;
    @media (min-width: $_footer-mw) {
      display: grid;
      grid-template:
        'nav social' auto
        'lang lang' auto
        'contact logos' auto
        'copyright copyright' auto
        / 2fr 1fr;
    }

    @media (min-width: $_footer-mw) and (max-width: $_footer-w) {
      grid-template-columns: auto $largest-footer-icon-width;
    }
  }

  svg {
    fill: currentColor;
  }

  // Border line
  &__nav-languages,
  &__logos {
    border-block-end: 3px solid;
  }

  @media (max-width: $_footer-mw) {
    &__nav-pages,
    &__social {
      border-block-end: 3px solid;
    }
  }

  // Logos
  &__logos {
    @include footer-padding;
    display: flex;
    grid-area: logos;

    @media (min-width: $_footer-mw) and (max-width: $_footer-w) {
      flex-direction: column;
      text-align: end;

      &-link {
        padding-block-end: functions.padding(x-small);
      }
    }

    @media (min-width: $_footer-mw) {
      justify-content: flex-end;
      border-block-end: 0;
    }

    @media (max-width: $_footer-mw) {
      justify-content: space-between;
    }
  }

  &__logos-link {
    display: block;
    text-decoration: none;
  }

  &__logos-pfml {
    @include base-mixins.fluid-scale(width, 155px, $largest-footer-icon-width);
    @include base-mixins.fluid-scale(height, 38px, 50px);
  }

  &__logos-esd {
    @include base-mixins.fluid-scale(width, 104px, 138px);
    @include base-mixins.fluid-scale(height, 38px, 50px);
    @include base-mixins.fluid-scale(
      margin-inline-start,
      functions.padding(x-x-small),
      functions.padding(x-large)
    );
  }

  // Nav
  &__nav-pages,
  &__nav-languages {
    display: table;
    grid-area: nav;
    @include footer-padding(functions.padding(small));
    @media (max-width: $_footer-mw) {
      display: grid;
    }
    @media (min-width: $_footer-mw) {
      padding-block-start: 0;
    }
  }

  &__nav-languages {
    display: grid;
    grid-area: lang;
  }

  &__nav-languages > &__nav-list-group {
    display: flex;
    flex-wrap: wrap;
    padding-block-start: functions.padding(x-x-small);
  }

  &__nav-list-group {
    display: table-row;
    @media (max-width: $_footer-mw) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__nav-item {
    display: table-cell;
    @media (max-width: $_footer-mw) {
      display: block;
      width: 50%;
    }
  }

  &__nav-link {
    @include base-mixins.fluid-scale(
      margin-inline-end,
      functions.padding(x-small),
      functions.padding(small)
    );
    display: inline-block;
    margin-block-end: functions.padding(x-x-small);
    text-decoration: none;
  }

  // Social
  &__social {
    grid-area: social;
    padding-block-end: functions.padding(x-small);
    text-align: end;
  }

  &__social-item {
    display: inline-block;
  }

  &__social-link {
    padding: 0 5px 5px;
    font-size: 30px;
    text-decoration: none;
  }

  // Contact
  &__contact {
    grid-area: contact;
    @include footer-padding;
    @media (min-width: $_footer-mw) {
      max-inline-size: 300px;
    }
  }

  &__contact-text {
    padding-block-end: 10px;
    font-weight: functions.font-weight(black);
  }

  &__contact-link {
    text-decoration: underline;
  }

  // Copyright
  &__copyright {
    grid-area: copyright;
  }
}
