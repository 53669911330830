@use 'abstracts/functions';
@use 'abstracts/mixins/base';
@use 'abstracts/mixins/layout';

$_background-color: functions.help-center(color-primary);
$_mobile-padding: 56px;
$_desktop-padding: functions.padding(large);
$_input-inline-padding: 20px;
$_button-size-desktop: 30px;
$_button-size-mobile: 22px;
$_button-padding-desktop: $_button-size-desktop + $_input-inline-padding * 2;
$_button-padding-mobile: $_button-size-mobile + $_input-inline-padding * 2;

.m-search-hero {
  color: functions.palette(white);
  background-color: $_background-color;

  &__inner {
    @include layout.container-primary;
    @include base.fluid-scale(
      padding-block,
      $_mobile-padding,
      $_desktop-padding
    );
    display: flex;
    flex-direction: column;
    place-content: center;

    > * + * {
      @include base.fluid-scale(
        margin-block-start,
        40px,
        30px
      );
    }
  }

  &__heading {
    text-align: center;
  }

  &__form {
    position: relative;
    inline-size: 100%;
    max-inline-size: 575px;
    margin-inline: auto;
    color: $_background-color;
    background-color: functions.palette(white);
    border-radius: 8.667em;
  }

  &__input {
    @include base.fluid-scale(
      padding-inline-end,
      $_button-padding-mobile,
      $_button-padding-desktop
    );
    inline-size: 100%;
    padding-block: 0.806em;
    padding-inline-start: $_input-inline-padding;
    font-size: inherit;
    color: inherit;
    background-color: transparent;
    border: none;
    appearance: none;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: inherit;
    }
  }

  &__button {
    position: absolute;
    line-height: 1;
    cursor: pointer;
    transition: color 0.2s;
    transform: translateY(-50%);
    inset-block-start: 50%;
    inset-inline-end: $_input-inline-padding;

    &:hover {
      color: functions.palette(dark-turquoise);
    }
  }

  &__icon {
    @include base.fluid-scale(
      inline-size block-size,
      $_button-size-mobile,
      $_button-size-desktop
    );
    fill: currentColor;
  }
}
