@use 'abstracts/functions';
@use 'abstracts/mixins/typography';

.m-callout {
  display: flex;
  flex-direction: column;

  &__title {
    @include typography.heading-quaternary;
    order: 1;
    font-weight: functions.font-weight(book);
  }

  &__link {
    margin-block-start: 0;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__text {
    order: 1;
    margin-block-start: 0;
  }

  &__image {
    inline-size: 100%;
    margin-block-start: 0;
    margin-block-end: 1.25em;
  }
}
