@use 'abstracts/functions';

// Application-Wide Mixins
// -----------------------------------

// Fluid Elements
// --------------------------------------------------------
// Set min and max sizes and breakpoints and let SCSS fluidly scale different properties in-between

// Adapted from https://www.sassmeister.com/gist/7f22e44ace49b5124eec
// Usage: @include fluid-type(font-size, 16px, 22px, functions.break(60), functions.break(130));
@mixin fluid-scale($properties, $min-value, $max-value, $min-vw: functions.break(60), $max-vw: functions.break(130)) {
  @each $property in $properties {
    #{$property}: $min-value;
  }

  @media (min-width: $min-vw) {
    @each $property in $properties {
      #{$property}: calc(#{$min-value} + #{functions.strip-unit($max-value - $min-value)} * (100vw - #{$min-vw}) / #{functions.strip-unit($max-vw - $min-vw)});
    }
  }

  @media (min-width: $max-vw) {
    @each $property in $properties {
      #{$property}: $max-value;
    }
  }
}

// Media queries
// --------------------------------------------------------
// Respond to viewport size
// $size -> viewport size
// $operator -> 'min' or 'max'
// $aspect -> 'width' or 'height'
@mixin respond($size, $operator: min, $aspect: width) {
  @media all and (#{$operator}-#{$aspect}: $size) {
    @content;
  }
}
