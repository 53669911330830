@use 'abstracts/mixins/typography';
@use 'abstracts/functions';
@use 'abstracts/mixins/base' as base-mixins;


.wp-block-table {
  overflow-x: auto;

  table {
    @include base-mixins.respond(functions.break(60)) {
      table-layout: fixed;
    }
  }

  th {
    @include typography.heading-tertiary;
    color: functions.palette(dark-blue);
    text-align: left;
  }

  th, td {
    padding: functions.padding(x-x-small) functions.padding(x-small) functions.padding(x-x-small) 0;
  }

  thead, tbody {
    th, td {
      border-bottom: 2px solid functions.palette(dark-blue);
    }
  }

  tfoot {
    td {
      font-style: italic;
    }
  }

  table + figcaption {
    padding-top: functions.padding(x-x-small);
    font-style: italic;
    text-align: center;
  }
}
