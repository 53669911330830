@use 'abstracts/functions';

// Appearance mixins
// --------------------
// These should be limited to appearance-related properties only.

@mixin focus-default {
  .js-focus-visible &:focus:not(.focus-visible) {
    outline: 0;
  }

  &.focus-visible {
    outline: solid 3px;
  }
}

@mixin box-shadow {
  box-shadow: 0 3px 20px rgba(#000, 0.12);
}

// Icons
// --------------------------------------------------------
@mixin proto-icon {
  display: inline;
  inline-size: 1em;
  block-size: 1em;
  vertical-align: middle;
  fill: currentColor;
}

// Links, Buttons
// --------------------------------------------------------
@mixin button-unstyled {
  padding: 0;
  color: inherit;
  background: transparent;
  border: 0;
  appearance: none;
}

@mixin button-base {
  display: inline-flex;
  align-items: center;
  padding: 0.5em 0.778em 0.39em;
  font-size: 14px;
  font-weight: functions.font-weight(bold);
  color: functions.palette(white);
  text-decoration: none;
  cursor: pointer;
  transition: opacity 0.2s, color 0.2s, background-color 0.2s,
    transform 0.2s ease-out;

  &:hover,
  &:focus-visible {
    background-color: functions.palette(dark-turquoise);
    outline: 0;
  }

  &[disabled],
  &--is-disabled {
    &:hover,
    &:focus-visible {
      cursor: default;
    }
  }
}

// hr Separators
// --------------------------------------------------------
@mixin line-separator {
  display: block;
  content: '';
  border-block-start: 1px solid rgba(functions.palette(neutral-80), 0.25);
}

// li Separators
// --------------------------------------------------------
@mixin list-items-separator {
  border-block-start: 1px solid rgba(functions.palette(neutral-80), 0.25);
}

// Form elements
// --------------------------------------------------------
@mixin input-base {
  font-size: inherit;
  font-weight: functions.font-weight(black);
  color: functions.palette(dark-blue);
  background-color: transparent;
  border: none;
  border-block-end: 3px solid functions.palette(dark-blue);
  transition: color 0.2s, border 0.2s;
  appearance: none;

  &::placeholder {
    font-size: 0.85em;
  }

  &:focus {
    outline: 0;
  }

  &:focus-visible {
    border-bottom-color: functions.palette(
      dark-turquoise
    ); // postcss-logical doesn't transform this property correctly
  }

  &[disabled] {
    color: rgba(functions.palette(dark-blue), 0.5);
    border-bottom-color: rgba(
      functions.palette(dark-blue),
      0.5
    ); // postcss-logical doesn't transform this property correctly
  }
}
