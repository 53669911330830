@use 'abstracts/functions';
@use 'abstracts/mixins/base';
@use 'abstracts/mixins/layout' as layout;
@use 'abstracts/variables';

.m-breadcrumbs {
  &--mobile {
    @media (min-width: variables.$header-breakpoint) {
      display: none;
    }
  }

  &--desktop {
    @media (max-width: variables.$header-breakpoint - 1) {
      display: none;
    }
  }

  padding-block-start: 17px;
  padding-block-end: 19px;
  overflow-x: auto;
  font-weight: functions.font-weight(book);
  color: functions.palette(dark-blue);
  background-color: functions.palette(seafoam);

  &__inner {
    @include layout.container-primary;
    @include base.fluid-scale(font-size, 13px, 14px);
    box-sizing: border-box;

    display: flex;
    align-items: center;
    margin: auto;
    @include base.fluid-scale(
      padding-inline-start,
      functions.padding(x-small),
      functions.padding(large),
      variables.$header-breakpoint
    );
    @include base.fluid-scale(
      padding-inline-end,
      functions.padding(x-small),
      functions.padding(large),
      variables.$header-breakpoint
    );
  }

  &__item {
    display: flex;
    flex-grow: 0;
    gap: 8px;
    align-items: center;

    &--active {
      font-weight: functions.font-weight(bold);
    }
  }

  &__item + &__item {
    padding-inline-start: 8px;
  }

  &__link {
    text-decoration: none;
    white-space: nowrap;
    transition: color 300ms ease-in;

    &:hover {
      color: lighten(functions.palette(dark-blue), 5);
    }
  }

  &__caret {
    width: 12px;
    margin-top: 3px;
    transform: rotate(0deg);

    &--rotated {
      transform: rotate(180deg);
    }
  }
}
