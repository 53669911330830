@use '../abstracts/variables';
@use '../abstracts/mixins/appearance';

// Appearance
// --------------------

// Appearance mixins contain primarily texture parameters: background-color, transparency, borders, etc.

// These should be limited to appearance-related properties only.

// Visibility
// --------------------------------------------------------
.a-pos-rel {
  position: relative;
}

.a-pos-abs {
  position: absolute;
}

.a-hidden {
  position: absolute;
  inline-size: 1px;
  block-size: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  border: 0;
}

.a-hide-overflow {
  overflow: hidden;
}

// Color
// --------------------------------------------------------
// Create a `.a-color-` class for each color in $palette map
@each $name, $value in variables.$palette {
  .a-color-#{"" + $name} {
    color: $value;
  }
}

// Create a `.a-bg-` class for each color in $palette map
@each $name, $value in variables.$palette {
  .a-bg-#{"" + $name} {
    background-color: $value;
  }
}

// Icons
// --------------------------------------------------------
.a-icon {
  @include appearance.proto-icon;

  &--rotate-180 {
    transform: rotate(180deg);
  }
}
