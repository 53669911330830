@use 'abstracts/functions';
@use 'abstracts/mixins/layout';
@use 'abstracts/mixins/base';

$_item-min-width: 275px;
$_gap: 2.875em;

.m-callout-grid {
  &__header {
    @include base.fluid-scale(
      margin-block-end,
      functions.padding(small),
      functions.padding(medium)
    );
    text-align: center;
  }
}
