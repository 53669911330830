@use 'abstracts/functions';
@use 'abstracts/mixins/base' as base-mixins;

.m-icon-text {
  @include base-mixins.fluid-scale(
    margin-block-start,
    functions.padding(x-small),
    functions.padding(large)
  );

  @include base-mixins.fluid-scale(
    padding,
    functions.padding(x-small),
    functions.padding(small)
  );

  @include base-mixins.respond(functions.break(60)) {
    flex-direction: row;
  }

  display: flex;
  flex-direction: column;
  background-color: functions.palette(white);

  &__image {
    @include base-mixins.respond(functions.break(60)) {
      flex: 0 0 194px;
      align-self: flex-start;
      padding-block-end: 0;
      margin-inline-end: functions.padding(large);
    }

    align-self: center;
    padding-block-end: functions.padding(x-small);
  }

  &__body {
    @include base-mixins.respond(functions.break(60)) {
      flex: 1 1 auto;
    }
  }

  strong {
    color: inherit;
  }
}
