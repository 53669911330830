@use 'sass:math';
@use 'abstracts/functions';
@use 'abstracts/mixins/layout';
@use 'abstracts/mixins/base';
@use 'abstracts/mixins/appearance';
@use 'abstracts/variables';

$_button-padding-vertical: 25px;
$_button-padding-lateral: 25px;
$_mobile-nav-padding-vertical: functions.padding(x-small);
$_mobile-nav-padding-lateral: functions.padding(medium);

.m-header {
  @include appearance.box-shadow;
  position: sticky;
  inset-block-start: 0;
  font-size: 16px;
  font-weight: functions.font-weight(black);
  line-height: 1;
  color: functions.palette(dark-blue);

  @media (min-width: variables.$admin-bar-pos-breakpoint) {
    inset-block-start: var(--admin-bar-height, 0px);
  }

  &__wrapper {
    background-color: functions.palette(white);
    border-block-end: 1px solid rgba(functions.palette(neutral-80), 0.25);
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    max-inline-size: functions.container-width(max);
    @include base.fluid-scale(
      padding-inline-start,
      functions.padding(x-small),
      functions.padding(large),
      variables.$header-breakpoint
    );
    @include base.fluid-scale(
      padding-inline-end,
      functions.padding(x-small),
      functions.padding(large),
      variables.$header-breakpoint
    );
    margin: auto;
  }

  &__start-block {
    display: flex;
    align-items: center;
  }

  &__end-block {
    @include base.fluid-scale(
      padding-block-start,
      11px,
      functions.padding(small),
      variables.$header-breakpoint
    );
  }

  &__link {
    display: inline-block;
    text-decoration: none;
  }

  &__logo,
  &__hamburger {
    position: relative;

    .has-nav-toggled:not(.has-mobile-subnav-toggled) & {
      color: functions.palette(white);
      transition: color 0.3s ease-out;
    }

    .has-nav-untoggled & {
      transition: none;
    }
  }

  &__logo {
    @include base.fluid-scale(width, 188.5px, 230px);
    @include base.fluid-scale(height, 46px, 56.12px);
    fill: currentColor;
  }

  &__nav {
    inline-size: 100%;
    text-align: center;

    &--top-row {
      text-transform: uppercase;

      a {
        font-weight: functions.font-weight(book);
      }
    }

    &-list {
      display: block;
      justify-content: flex-end;
      inline-size: 100%;
      margin: 0;
    }

    &-link {
      display: flex;
      align-items: center;
      @include base.fluid-scale(
        padding-block-end,
        20px,
        functions.padding(small),
        variables.$header-breakpoint
      );
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }

      &--with-border {
        cursor: pointer;
      }

      &--link-nested {
        padding-block-end: 0;

        > * {
          @include base.fluid-scale(
            padding-block-end,
            20px,
            functions.padding(small),
            variables.$header-breakpoint
          );
        }
      }
    }
  }

  &__nav-toggle {
    cursor: pointer;
    fill: currentColor;

    path {
      transition: opacity 0.3s ease-out;

      .has-nav-untoggled & {
        transition: none;
      }
    }

    .close {
      opacity: 0;

      .has-nav-toggled &,
      .has-mobile-subnav-toggled & {
        opacity: 1;
      }
    }

    .hamburger {
      opacity: 1;

      .has-nav-toggled &,
      .has-mobile-subnav-toggled & {
        opacity: 0;
      }
    }
  }

  &__subnav-toggle {
    position: relative;
    inset-block-start: 1.5px;
    cursor: pointer;
    transition: color 0.2s;

    &:hover {
      color: functions.palette(dark-turquoise);
    }
  }

  &__link-icon {
    flex-shrink: 0;
    fill: currentColor;

    .m-header__nav-link.is-toggled & {
      transform: rotate(180deg);
    }
  }

  // Mobile & desktop styles are very different,
  // so keep styles scoped by media query together
  @media (max-width: variables.$header-breakpoint - 1) {
    &__inner {
      align-items: center;
      padding: 20px;
    }

    &__end-block {
      position: relative; // reset stacking context for IE11
      padding: 0;
    }

    &__nav-item {
      &--desktop-search {
        display: none;
      }

      &--mobile-search {
        padding-block-start: $_mobile-nav-padding-vertical * 2;
        padding-block-end: math.div($_mobile-nav-padding-vertical, 4);
        border-block-end: 2px solid currentColor;
      }
    }

    &__nav-link {
      justify-content: space-between;
      inline-size: 100%;
      padding-block-start: $_mobile-nav-padding-vertical * 2;
      padding-block-end: math.div($_mobile-nav-padding-vertical, 5);
      border-block-end: 2px solid currentColor;

      &--link-nested {
        > * {
          padding-block-end: 0;
        }
      }
    }

    &__link-icon {
      position: relative;
      inset-block-start: -4px;
      transform: rotate(-90deg);
    }

    &__subnav-toggle {
      flex-grow: 1;
      text-align: end;
    }
  }

  @media (min-width: variables.$header-breakpoint) {
    &__end-block {
      align-self: end;
      margin-inline-end: -1 * $_button-padding-lateral;
    }

    &__hamburger {
      display: none;
    }

    &__nav-list {
      display: flex;
    }

    &__nav-item {
      &:first-child {
        margin-block-start: 0;
      }

      &--mobile-search {
        display: none;
      }
    }

    &__nav-link {
      padding-inline-start: $_button-padding-lateral;
      padding-inline-end: $_button-padding-lateral;
      block-size: 100%;

      &:hover {
        text-decoration: underline;
      }

      &--with-border {
        padding-inline-start: $_button-padding-lateral;
        padding-inline-end: $_button-padding-lateral;
        border-block-end: 5px solid transparent;
        block-size: 100%;

        &:hover,
        &.is-toggled,
        &[aria-current='page'] {
          text-decoration: none;
          border-bottom-color: inherit; // postcss-logical doesn't transform this property correctly
          transition: border-block-end 0.1s;
        }
      }
    }

    &__link-icon {
      margin-inline-start: 5px;

      .m-header__nav-link.is-toggled & {
        transition: transform 0.3s ease-out;
        transform: rotate(180deg);
      }
    }
  }
}
