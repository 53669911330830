@use 'abstracts/functions';

$_checkbox-width: 20px;
$_checkbox-padding: 10px;

.m-search-filters {
  padding: 0;
  margin: 0;
  color: functions.help-center(color-primary);
  border: none;

  &__heading {
    margin-block-end: functions.padding(medium);
    font-weight: functions.font-weight(black);
  }

  &__filter {
    position: relative;
    display: block;
    padding-inline-start: $_checkbox-width + $_checkbox-padding;
    cursor: pointer;

    & + & {
      margin-block-start: functions.padding(medium);
    }
  }

  &__label {
    &::after {
      position: absolute;
      display: block;
      content: '';
      border: 1px solid;
      inline-size: $_checkbox-width;
      block-size: $_checkbox-width;
      inset-block-start: -1.5px;
      inset-inline-start: 0;

      .m-search-filters__input:checked ~ & {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 24 24' fill='%2305626F'%3E%3Cpath d='M9 21.035l-9-8.638 2.791-2.87 6.156 5.874 12.21-12.436 2.843 2.817z'/%3E%3C/svg%3E");
      }
    }

    .m-search-filters__input:focus-visible ~ & {
      text-decoration: underline;
    }
  }

  &__input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  &__submit {
    margin-block-start: functions.padding(medium);
  }
}
