@use 'abstracts/functions';
@use 'abstracts/mixins/base' as base-mixins;
@use 'abstracts/mixins/layout' as layout;
@use 'abstracts/mixins/typography' as typography;

.m-404 {
  @include layout.container-primary;
  @include base-mixins.fluid-scale(
    padding-block,
    functions.padding(medium),
    functions.padding(x-large) * 2
  );
  color: functions.palette(dark-blue);

  &__text {
    @include base-mixins.fluid-scale(
      padding-block-start,
      functions.padding(x-small),
      functions.padding(medium)
    );
    @include typography.copy-lead;
  }
}
