@use 'abstracts/functions';
@use 'abstracts/mixins/appearance';
@use 'abstracts/mixins/base';
@use 'abstracts/variables';
@use 'abstracts/mixins/layout';

$_checkbox-break: functions.break(60);

// RTE style overrides
.m-content-rte {
  .wp-block-contact-form-7-contact-form-selector {
    .wpcf7 {
      br {
        display: none;
      }

      p {
        padding: 0;
        margin: 0;
      }
    }
  }
}

.wp-block-contact-form-7-contact-form-selector {
  display: flex;
  justify-content: center;
  width: 100%;
  @include layout.container-primary;

  &:not(.m-post__content > &) {
    @include base.fluid-scale(
      margin-block-start margin-block-end,
      functions.padding(medium),
      functions.padding(x-large)
    );
  }

  .wpcf7 {
    width: 100%;
    max-width: 800px;
    font-weight: functions.font-weight(bold);
    color: functions.palette(dark-blue);

    label {
      font-weight: functions.font-weight(bold);
      color: functions.palette(dark-blue);

      > .wpcf7-form-control-wrap {
        @include base.fluid-scale(margin-block-start, 8px, 12px);
      }
    }

    p {
      > * + *:not(.wpcf7-not-valid-tip) {
        @include base.fluid-scale(margin-block-start, 24px, 48px);
      }
    }

    &-form {
      display: flex;
      flex-direction: column;
      width: 100%;
      @include base.fluid-scale(row-gap, 24px, 48px);
    }

    &-form-control-wrap {
      display: block;

      &:not(:first-child) {
        margin-block-start: 12px;
      }
    }

    &-text,
    &-number {
      @include appearance.input-base;
      width: 100%;
      font-size: 1em;
      font-weight: functions.font-weight(book);
      line-height: 2em;
      border: 3px solid;

      &::placeholder {
        font-size: 1em;
      }
    }

    &-textarea {
      @include appearance.input-base;
      width: 100%;
      padding: 0.5em;
      font-size: 1em;
      font-weight: functions.font-weight(book);
      border: 3px solid;
      resize: none;

      &::placeholder {
        font-size: 1em;
      }
    }

    &-date {
      @include appearance.input-base;
      width: 100%;
      font-size: 1em;
      font-weight: functions.font-weight(book);
    }

    // There's a known bug with...
    input[type='date']::-webkit-calendar-picker-indicator {
      padding: 5px;
      color: functions.palette(dark-blue);
      pointer-events: auto;
      cursor: pointer;
      background-image: url('../icons/calendar.svg');

      &:hover {
        background-image: url('../icons/calendar-hover.svg');
      }
    }

    &-radio,
    &-checkbox {
      display: flex;
      gap: 24px;

      @media (max-width: $_checkbox-break) {
        flex-direction: column;
      }

      .wpcf7-list-item {
        display: flex;
        gap: 18px;
        align-items: center;
        margin-inline-start: 0;
        font-weight: functions.font-weight(book);

        label {
          display: flex;
          gap: 18px;
          align-items: center;
          font-weight: inherit;
        }
      }
    }

    &-radio {
      input {
        display: inline-block;
        width: 14px;
        height: 14px;
        cursor: pointer;
        border-radius: 50%;
        outline: 2px solid functions.palette(dark-blue);
        outline-offset: 2px;
        appearance: none;

        &:checked {
          background: functions.palette(dark-blue);
        }

        &:focus-visible {
          outline-color: functions.palette(dark-turquoise);
        }
      }
    }

    &-checkbox {
      input {
        display: inline-block;
        width: 14px;
        height: 14px;
        cursor: pointer;
        border-radius: 1px;
        outline: 2px solid functions.palette(dark-blue);
        outline-offset: 2px;
        appearance: none;

        &:checked {
          background: functions.palette(dark-blue);
          background-image: url('../icons/check.svg');
        }

        &:focus-visible {
          outline-color: functions.palette(dark-turquoise);
        }
      }
    }

    &-submit {
      @include appearance.button-unstyled;
      @include appearance.button-base;
      display: flex;
      background-color: functions.palette(dark-blue);

      @media (min-width: $_checkbox-break) {
        margin-inline-start: auto;
      }
    }

    &-spinner {
      display: none;
    }

    &-not-valid-tip {
      padding-block-start: functions.padding(x-x-small);
      margin-block-start: 0.5em;
      font-size: 1em;
      font-style: italic;
      font-weight: functions.font-weight(book);
      color: functions.palette(magenta);
    }

    form:not(.sent) .wpcf7-response-output {
      color: functions.palette(magenta);
    }

    form.sent .wpcf7-response-output {
      color: functions.palette(dark-turquoise);
    }

    &-response-output {
      margin: 0 !important;
      font-size: 1.25em;
      border: none !important;
    }
  }
}
