@use 'abstracts/functions';
@use 'abstracts/mixins/base';

$_breakpoint: functions.help-center(layout-breakpoint);

@mixin show-desktop {
  @include base.respond($_breakpoint + 1, max) {
    display: none;
  }
}

@mixin show-mobile {
  @include base.respond($_breakpoint) {
    display: none;
  }
}

@mixin on-desktop {
  @include base.respond($_breakpoint) {
    @content;
  }
}

@mixin on-mobile {
  @include base.respond($_breakpoint + 1, max) {
    @content;
  }
}
