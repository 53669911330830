@use 'abstracts/functions';

// @font-face-specific mixins
// -----
@mixin embedFont($font-name, $font-filepath-and-name, $font-weight, $font-style) {
  @font-face {
    font-family: $font-name;
    font-style: $font-style;
    font-weight: $font-weight;
    src:
      url('../fonts/#{$font-filepath-and-name}.woff2') format('woff2'),
      url('../fonts/#{$font-filepath-and-name}.woff') format('woff');
    font-display: swap;
  }
}

// @font-face declarations
// --------------------------
// Do your @font-face embedding here, in the privacy of your own partial
// `embedFont` must be used once per combination of weight and style

@include embedFont('Santral', 'Santral-Book', functions.font-weight(book), normal);
@include embedFont('Santral', 'Santral-BookItalic', functions.font-weight(book), italic);
@include embedFont('Santral', 'Santral-Bold', functions.font-weight(bold), normal);
@include embedFont('Santral', 'Santral-BoldItalic', functions.font-weight(bold), italic);
@include embedFont('Santral', 'Santral-Black', functions.font-weight(black), normal);

@include embedFont('Noto Sans Arabic', 'NotoSansArabic-Regular', functions.font-weight(book), normal);
@include embedFont('Noto Sans Arabic', 'NotoSansArabic-Bold', functions.font-weight(bold), normal);
@include embedFont('Noto Sans Arabic', 'NotoSansArabic-Black', functions.font-weight(black), normal);

@include embedFont('Noto Sans Cyrillic', 'NotoSansCyrillic-Regular', functions.font-weight(book), normal);
@include embedFont('Noto Sans Cyrillic', 'NotoSansCyrillic-Bold', functions.font-weight(bold), normal);
@include embedFont('Noto Sans Cyrillic', 'NotoSansCyrillic-Black', functions.font-weight(black), normal);

@include embedFont('Noto Sans Gurmukhi', 'NotoSansGurmukhi-Regular', functions.font-weight(book), normal);
@include embedFont('Noto Sans Gurmukhi', 'NotoSansGurmukhi-Bold', functions.font-weight(bold), normal);

@include embedFont('Noto Sans Khmer', 'NotoSansKhmer-Regular', functions.font-weight(book), normal);
@include embedFont('Noto Sans Khmer', 'NotoSansKhmer-Bold', functions.font-weight(bold), normal);
@include embedFont('Noto Sans Khmer', 'NotoSansKhmer-Black', functions.font-weight(black), normal);

@include embedFont('Noto Sans Lao', 'NotoSansLao-Regular', functions.font-weight(book), normal);
@include embedFont('Noto Sans Lao', 'NotoSansLao-Bold', functions.font-weight(bold), normal);
@include embedFont('Noto Sans Lao', 'NotoSansLao-Black', functions.font-weight(black), normal);

@include embedFont('Noto Sans Tagalog', 'NotoSansTagalog-Regular', functions.font-weight(book), normal);

@include embedFont('Noto Sans Vietnamese', 'NotoSansVietnamese-Regular', functions.font-weight(book), normal);
@include embedFont('Noto Sans Vietnamese', 'NotoSansVietnamese-Bold', functions.font-weight(bold), normal);
@include embedFont('Noto Sans Vietnamese', 'NotoSansVietnamese-Black', functions.font-weight(black), normal);

@include embedFont('Noto Sans Osmanya', 'NotoSansOsmanya-Regular', functions.font-weight(book), normal);

@include embedFont('Noto Sans Ethiopic', 'NotoSansEthiopic-Regular', functions.font-weight(book), normal);
@include embedFont('Noto Sans Ethiopic', 'NotoSansEthiopic-Bold', functions.font-weight(bold), normal);
@include embedFont('Noto Sans Ethiopic', 'NotoSansEthiopic-Black', functions.font-weight(black), normal);
