@use 'abstracts/functions';
@use 'abstracts/mixins/base' as base-mixins;
@use 'abstracts/mixins/layout' as layout;
@use 'abstracts/mixins/typography' as typography;

$_min-width: functions.break(60);

.m-sign-up {
  background-color: functions.palette(light-blue);
  @include base-mixins.fluid-scale(
    padding-block,
    functions.padding(medium),
    functions.padding(x-large)
  );
  @media (min-width: $_min-width) {
    text-align: center;
  }

  &__inner {
    @include layout.container-primary;
    @media (min-width: $_min-width) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__text {
    @include typography.proto-heading;
    @include base-mixins.fluid-scale(font-size, 16px, 24px);
    margin-block-end: functions.padding(x-small);
    font-weight: functions.font-weight(bold);
    line-height: 1.167;
    color: functions.palette(dark-blue);

    @media (min-width: $_min-width) {
      @include base-mixins.fluid-scale(
        margin-inline-end,
        functions.padding(x-small),
        functions.padding(large)
      );
      display: inline-block;
      margin-block-end: 0;
    }
  }

  &__button {
    background-color: functions.palette(dark-blue);

    @media (min-width: $_min-width) {
      position: relative;
      inset-block-start: -3px;
    }
  }
}
