@use 'abstracts/functions';

// State mixins
// --------------------

// Mixins and classes for setting styles based on component state.
// Classes are prepended with `is-` or `has-`.

@mixin has-scroll-lock {
  position: absolute;
  inset-block-start: 0;
  inline-size: 100%;
  overflow: hidden;

  &::after {
    position: absolute;
    inset-block-start: 0;
    inset-inline-start: 0;
    inline-size: 100%;
    block-size: 100%;
    content: '';
    background-color: rgba(functions.palette(white), 0.6);
    transition: background-color 0.2s linear;
  }
}
