@use 'abstracts/functions';
@use 'abstracts/mixins/appearance';
@use 'abstracts/mixins/layout';
@use 'abstracts/mixins/typography';
@use 'abstracts/mixins/base' as base-mixins;

$_icon-width-mobile: 250px;
$_icon-width-desktop: 336px;

.m-animated-icons {
  @include layout.container-primary;
  @include base-mixins.fluid-scale(
    margin-block-end,
    functions.padding(medium),
    functions.padding(x-x-large)
  );

  ol {
    margin: 0;
    list-style: none;
  }

  @include base-mixins.respond(functions.break(60)) {
    h3 {
      text-align: center;
    }
  }

  &__child {
    @include base-mixins.fluid-scale(
      margin-block-start,
      functions.padding(medium),
      functions.padding(x-x-large)
    );

    display: flex;
    flex-direction: column;
    align-items: center;

    @include base-mixins.respond(functions.break(60)) {
      flex-direction: row;
      padding-inline: functions.padding(x-small);
    }

    &:first-child,
    &:last-child {
      justify-content: flex-end;
    }
  }

  &__icon {
    inline-size: auto;
    block-size: auto;
    margin-block-end: functions.padding(small);

    @include base-mixins.fluid-scale(
      max-width,
      $_icon-width-mobile,
      $_icon-width-desktop
    );
  }

  &__text {
    max-inline-size: 500px;

    @include base-mixins.fluid-scale(
      margin-inline-start,
      functions.padding(x-x-small),
      functions.padding(x-x-large)
    );

    span {
      @include typography.heading-secondary;
      font-weight: functions.font-weight(book);
      color: functions.palette(dark-blue);
    }

    p {
      @include typography.copy-lead;
      @include base-mixins.fluid-scale(
        padding-block-start,
        functions.padding(x-x-small),
        functions.padding(small)
      );
    }
  }
}
