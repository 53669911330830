@use 'abstracts/functions';
@use 'abstracts/mixins/base' as base-mixins;

.m-validation {
  @include base-mixins.fluid-scale(font-size, 12px, 14px);
  @include base-mixins.fluid-scale(line-height, 18px, 22px);
  padding-block-start: functions.padding(x-x-small);
  font-style: italic;
  color: functions.palette(neutral-80);
}
