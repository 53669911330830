@use 'abstracts/functions';
@use 'abstracts/mixins/base' as base-mixins;
@use 'abstracts/mixins/layout' as layout;

.m-notification {
  padding-block-start: functions.padding(x-x-small);
  padding-block-end: functions.padding(x-x-small);
  font-weight: functions.font-weight(bold);
  background-color: functions.palette(turquoise);

  &__inner {
    @include layout.container-max;
    @include base-mixins.fluid-scale(
      padding-inline,
      functions.padding(x-x-small),
      functions.padding(small)
    );
    display: flex;
    align-items: center;
  }

  &__text {
    @include base-mixins.fluid-scale(font-size, 12px, 13px);
    flex-grow: 1;
    inline-size: 100%;

    @include base-mixins.respond(functions.break(60)) {
      text-align: center;
    }
  }

  &__button {
    flex-shrink: 0;
    color: functions.palette(black);

    &:hover {
      color: functions.palette(white);
      background-color: inherit;
    }
  }

  &--is-hidden,
  .drawer-is-open & {
    display: none;
  }
}
