@use 'abstracts/functions';

.m-desc-list {
  border: 2px solid functions.palette(dark-blue);

  &__term,
  &__details {
    padding: 0.778em 0.778em 0.667em;
  }

  &__term {
    font-weight: functions.font-weight(bold);
    color: functions.palette(white);
    background-color: functions.palette(dark-blue);
  }
}
