@use 'abstracts/functions';
@use 'abstracts/mixins/base' as base;
@use 'abstracts/mixins/layout' as layout;

.m-posts {
  @include base.fluid-scale(
    padding-block-start,
    0px,
    25px
  );

  &__title,
  &__date,
  &__link {
    color: functions.palette(dark-blue);
  }

  &__link {
    font-weight: functions.font-weight(bold);
  }

  &__title {
    padding-block-start: 20px;
    padding-block-end: 4px;
    font-size: 24px;
  }

  &__text {
    padding-block: 20px;
  }

  &__image {
    width: 100%;
  }

  .m-pagination {
    border-bottom: none;
  }
}
