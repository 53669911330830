@use 'abstracts/functions';
@use 'abstracts/mixins/base';
@use 'abstracts/mixins/layout';
@use 'abstracts/variables';

// Component Layout
// -------------------

// Layout-only mixins and utility classes.

// They may contain:
// Nested classes/elements that have structural parameters only

// Utility Spacing Classes
// --------------------------------------------------------

// Create a a padding class in each direction for each value in $padding-sizes map
@each $name, $value in variables.$padding-sizes {
  .l-pad-top-#{$name} {
    padding-block-start: $value;
  }

  .l-pad-right-#{$name} {
    padding-inline-end: $value;
  }

  .l-pad-bottom-#{$name} {
    padding-block-end: $value;
  }

  .l-pad-left-#{$name} {
    padding-inline-start: $value;
  }
}

.l-page-section-padding-vertical {
  @include base.fluid-scale(
    padding-block,
    functions.padding(medium),
    functions.padding(x-x-large)
  );
}

// Containers
// --------------------------------------------------------
.l-container-max {
  @include layout.container-max;
}

.l-container-primary {
  @include layout.container-primary;
}

.l-content-and-component {
  $_content-width: 395px;
  $_gap-mobile: 40px;
  $_gap-desktop: 5em;
  $_breakpoint: $_content-width * 2 + $_gap-mobile;

  display: flex;
  flex-direction: column;

  > :first-child {
    flex-grow: 1;
  }

  > :last-child {
    flex-basis: 0;
    flex-grow: 999;
    margin-block-start: $_gap-mobile;
  }

  @include base.respond($_breakpoint) {
    flex-direction: row;

    > :first-child {
      flex-basis: 395px;
    }

    > :last-child {
      margin-block-start: 0;
      margin-inline-start: $_gap-desktop;
    }
  }
}
