@use 'abstracts/functions';
@use 'abstracts/mixins/base' as base-mixins;
@use 'abstracts/mixins/layout' as layout-mixins;
@use 'abstracts/mixins/appearance' as appearance-mixins;

$_column-break: functions.break(60);

.m-application-process {
  @include base-mixins.fluid-scale(
    padding-block-start,
    47px,
    functions.padding(x-x-large),
    $_column-break
  );
  @include base-mixins.fluid-scale(
    padding-block-end,
    functions.padding(medium),
    96px,
    $_column-break
  );
  @include base-mixins.fluid-scale(
    padding-inline,
    functions.padding(x-small),
    functions.padding(x-large),
    $_column-break
  );
  background-color: functions.palette(seafoam);

  &__inner {
    @include layout-mixins.container-wide;
    @include base-mixins.fluid-scale(padding, 14px, 70px, $_column-break);
    @include base-mixins.fluid-scale(
      padding-inline-start padding-block-end,
      14px,
      60px,
      $_column-break
    );
    background-color: functions.palette(white);
  }

  .m-image-repeater {
    display: flex;
    flex-direction: column;

    &__item {
      &--container {
        @include base-mixins.fluid-scale(
          inline-size,
          90px,
          150px,
          $_column-break
        );
        @include base-mixins.fluid-scale(
          margin-block-end,
          functions.padding(x-small),
          functions.padding(large),
          $_column-break
        );

        &:first-child {
          @include base-mixins.fluid-scale(
            margin-block-start,
            0px,
            functions.padding(x-small),
            $_column-break
          );
        }

        &:last-child {
          margin-block-end: 0;
        }
      }
    }
  }

  &__header {
    @include base-mixins.fluid-scale(
      padding-block-end,
      functions.padding(x-small),
      70px,
      $_column-break
    );
    @include base-mixins.fluid-scale(font-size, 28px, 40px);
    text-align: center;
  }

  &__body {
    display: flex;
  }

  ol {
    inline-size: 100%;
    @include base-mixins.fluid-scale(
      padding-inline-start,
      functions.padding(small),
      70px,
      $_column-break
    );
    margin: 0;
    color: functions.palette(dark-blue);
    list-style-position: inside;

    li {
      @include appearance-mixins.list-items-separator;
      @include base-mixins.fluid-scale(font-size, 14px, 24px);
      line-height: 1.1;
      @include base-mixins.fluid-scale(
        padding-block,
        16px,
        35px,
        $_column-break
      );

      &:first-child {
        padding-block-start: 0;
        border: none;
      }

      &:last-child {
        padding-block-end: 0;
      }
    }
  }

  @media (min-width: functions.break(40)) and (max-width: $_column-break) {
    .m-image-repeater {
      max-height: 118px;

      &__item {
        height: 100%;

        &--container {
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  @media (max-width: $_column-break) {
    &__body {
      flex-direction: column;
    }

    .m-image-repeater {
      flex-direction: row;
      justify-content: space-around;
      inline-size: 100%;
      padding-block-end: functions.padding(x-small);

      &__item {
        padding: 0;

        &--container {
          padding-inline: 11px;
          margin-block: 0;
          inline-size: auto;

          &:first-child {
            padding-inline-start: 0;
            margin-block-start: 0;
          }

          &:last-child {
            padding-inline-end: 0;
          }
        }
      }
    }

    ol {
      inline-size: 100%;
      padding-inline-start: 0;
    }
  }
}
