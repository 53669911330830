@use 'abstracts/functions';
@use 'abstracts/mixins/appearance';
@use 'abstracts/mixins/base' as base-mixins;

.m-download-dropdown {
  @include base-mixins.fluid-scale(
    padding-block-start,
    functions.padding(small),
    functions.padding(medium)
  );

  &__select {
    @include base-mixins.fluid-scale(
      padding-block-end,
      functions.padding(x-small),
      functions.padding(small)
    );
  }
}
