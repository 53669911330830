@use 'abstracts/functions';
@use 'abstracts/mixins/base';
@use 'abstracts/mixins/layout';
@use 'abstracts/mixins/typography';
@use './mixins' as help-center-mixins;

$_item-margin: 16px;

.m-document-list {
  @include base.fluid-scale(margin-block-start, 50px, 70px);
  @include help-center-mixins.show-desktop;
  padding: functions.padding(medium);
  color: functions.help-center(color-primary);
  background-color: functions.palette(seafoam);

  &__heading {
    @include typography.heading-quaternary;
    margin-block-end: 15px;
    text-align: center;
  }

  &__list {
    margin-block-end: -1 * $_item-margin;

    &--columns-2 {
      columns: 2;
    }

    &--columns-3 {
      columns: 3;
    }
  }

  &__item {
    margin-block-end: $_item-margin;
    break-inside: avoid;
  }

  &__link {
    display: flex;
    align-items: baseline;
    justify-content: center;
  }

  &__icon {
    position: relative;
    top: 11px;
    flex: 0 0 auto;
    margin-right: 8px;
    fill: currentColor;
  }

  &__name {
    flex: 1 1 auto;
  }
}
