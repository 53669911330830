@use 'abstracts/functions';
@use 'abstracts/mixins/base';
@use 'abstracts/mixins/typography';

// The table layout changes when there's less space for it:
// 1) < 600px
// 2) 830px < viewport < 1070 (due to calculator being in a two-column wrapper)
$_breakpoint-1: 600px;
$_breakpoint-2: 830px;
$_breakpoint-3: 1070px;

@mixin narrow-table-styles {
  @media all and (max-width: $_breakpoint-1) {
    @content;
  }

  @media all and (min-width: $_breakpoint-2) and (max-width: $_breakpoint-3) {
    @content;
  }
}

@mixin small-text {
  padding-bottom: 0;
  font-size: 14px;
  line-height: 1.35;
}

.m-premium-calculator {
  /* autoprefixer grid: off */
  @include base.fluid-scale(grid-gap, 24px, 50px);
  @include base.fluid-scale(padding, 16px, 44px);
  display: grid;
  grid-template:
    'header' auto
    'form' auto
    'paid-leave-table' auto
    'wa-cares-table' auto
    'footer' auto / auto;
  min-inline-size: 325px;
  color: functions.palette(dark-blue);
  background-color: functions.palette(white);

  @include narrow-table-styles {
    text-align: center;
  }

  &__header {
    grid-area: header;
  }

  &__title {
    @include typography.heading-secondary;
    font-weight: functions.font-weight(book);

    + p {
      @include base.fluid-scale(
        margin-block-start,
        functions.padding(x-x-small),
        functions.padding(x-small)
      );
      padding-block-end: 0;
    }
  }

  &__form {
    --select-padding-block-start: 6px;

    display: flex;
    flex-wrap: wrap;
    grid-area: form;
    row-gap: 16px;
    column-gap: 16px;
    align-items: stretch;

    @include narrow-table-styles {
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
    }
  }

  &__disclaimer {
    flex-basis: 100%;

    p {
      @include small-text;
    }
  }

  &__table {
    inline-size: 100%;
    font-size: 14px;
    line-height: 1.35;
    color: inherit;
    table-layout: fixed;
    border-collapse: collapse;

    &--paid-leave {
      grid-area: paid-leave-table;
    }

    &--wa-cares {
      grid-area: wa-cares-table;
    }

    th {
      font-weight: inherit;
    }

    td {
      text-align: center;
    }

    thead,
    tfoot {
      background-color: #f6faf8;
    }

    tfoot {
      font-weight: functions.font-weight(bold);
    }
  }

  &__table-cell {
    padding: 10px 9px 8px;
    border-block-end: 1px solid;

    thead & {
      border-block-start: 1px solid;
    }

    &--mobile {
      display: none;
      text-align: center;
      border-block-end-width: 0;

      @include narrow-table-styles {
        display: table-cell;
      }
    }

    &--desktop {
      text-align: start;

      @include narrow-table-styles {
        display: none;
      }
    }
  }

  &__caption {
    @include typography.copy-primary;
    padding-block-end: 0.333em;
    text-align: start;

    h3 {
      padding-block-end: 0;
      font: inherit;
      font-weight: functions.font-weight(bold);
    }

    p {
      @include small-text;
      padding-block-end: 0.333em;
      margin-block-start: 8px;
    }
  }

  &__footer {
    grid-area: footer;
  }
}
