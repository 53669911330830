@use 'abstracts/functions';
@use './mixins' as help-center-mixins;

.m-subcategory-list {
  color: functions.help-center(color-primary);

  &__item {
    @include help-center-mixins.on-mobile {
      padding-block: functions.padding(x-small);
      border-block-start: 1px solid rgba(#333, 0.25);
      border-block-end: 1px solid rgba(#333, 0.25);

      & + & {
        border-block-start: functions.padding(small);
      }
    }

    @include help-center-mixins.on-desktop {
      & + & {
        margin-block-start: functions.padding(small);
      }
    }
  }

  &__link {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    text-decoration: none;

    @include help-center-mixins.on-desktop {
      border-block-end: 1px solid;
    }

    &--active {
      @include help-center-mixins.on-desktop {
        font-weight: functions.font-weight(black);
        border-block-end: 4px solid;
      }
    }

    &:hover {
      text-decoration: none;
    }
  }

  &__icon {
    @include help-center-mixins.show-mobile;
    position: relative;
    top: 3px;
    fill: currentColor;
  }
}
