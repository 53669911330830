@use 'abstracts/functions';
@use './mixins' as help-center-mixins;

.m-primary-category-select {
  @include help-center-mixins.show-mobile;
  display: block;
  padding: 29px 18px;
  font-weight: functions.font-weight(black);
  color: functions.help-center(color-primary);
  background-color: functions.palette(seafoam);

  &__inner,
  &__select {
    inline-size: 100%;
  }
}
