@use 'abstracts/functions';

$_icon-size: 16px;

.m-back-link {
  display: flex;
  align-items: center;
  font-weight: functions.font-weight(bold);
  line-height: $_icon-size;
  text-decoration: none;

  &__icon {
    position: relative;
    fill: currentColor;
    transform: rotate(180deg);
    inset-block-start: -2px;
    inline-size: $_icon-size;
    block-size: $_icon-size;
  }

  &__text {
    padding-inline-start: 3px;
    text-decoration: underline;

    &--no-underline {
      text-decoration: none;
    }
  }
}
