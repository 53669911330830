@use 'abstracts/mixins/typography';
@use 'abstracts/functions';
@use 'abstracts/variables';

// Typography utility classes
// -----------------------------------
// These should be limited to `font` and other typographic properties only.

// Alignment
// --------------------------------------------------------
.t-align-center {
  text-align: center;
}

// Fonts
.t-cjk {
  font-family: variables.$font-stack-cjk;
}

.t-noto-sans {
  font-family: variables.$font-stack-noto-sans;
}

// Headings
// --------------------------------------------------------
.t-heading-primary {
  @include typography.heading-primary;
}

.t-heading-secondary {
  @include typography.heading-secondary;
}

.t-heading-tertiary {
  @include typography.heading-tertiary;
}

.t-heading-quaternary {
  @include typography.heading-quaternary;
}

// Copy
// --------------------------------------------------------
.t-copy-primary {
  @include typography.copy-primary;
}

.t-copy-bold {
  font-weight: functions.font-weight(bold);
}

.t-copy-book {
  font-weight: functions.font-weight(book);
}

.t-copy-black {
  font-weight: functions.font-weight(black);
}

.t-copy-lead {
  @include typography.copy-lead;
}

// Links
// --------------------------------------------------------
.t-link-underlined {
  @include typography.link-underlined;
}

// Lists
// --------------------------------------------------------
.t-list-unstyled {
  @include typography.list-unstyled;
}

.t-list-unordered {
  @include typography.list-unordered;
}

.t-list-ordered {
  @include typography.list-ordered;
}

// Misc
// --------------------------------------------------------
.t-hide-text-overflow {
  @include typography.hide-text-overflow;
}

.t-whitespace-nowrap {
  white-space: nowrap;
}

.t-text-decoration-none {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

// Wordpress custom block styles
// --------------------------------------------------------
.is-style-thin {
  font-weight: functions.font-weight(book);
}
