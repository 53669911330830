@use 'abstracts/functions';
@use 'abstracts/mixins/base';
@use 'abstracts/mixins/typography';
@use './mixins' as help-center-mixins;

.m-help-center-search-results {
  color: functions.help-center(color-primary);

  &__heading {
    @include typography.heading-quaternary;
    @include typography.hide-text-overflow;
    padding-block-end: 0.917em;
    border-block-end: 1px solid;
  }

  &__documents {
    @include base.fluid-scale(
      margin-block-start,
      functions.padding(large),
      functions.padding(small)
    );
  }

  &__questions {
    @include base.fluid-scale(
      margin-block-start,
      functions.padding(x-large),
      functions.padding(medium)
    );
  }
}
