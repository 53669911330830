@use 'sass:math';
@use 'abstracts/functions';
@use 'abstracts/mixins/layout';
@use 'abstracts/mixins/base';

$_item-min-width: 275px;
$_gap: 2.875em;

.m-three-columns {
  @include layout.container-primary;
  @include base.fluid-scale(
    margin-block-start,
    functions.padding(medium),
    functions.padding(x-large)
  );
  @include base.fluid-scale(
    margin-block-end,
    functions.padding(medium),
    functions.padding(x-large)
  );

  &__header {
    @include base.fluid-scale(
      margin-block-end,
      functions.padding(small),
      functions.padding(medium)
    );
    text-align: center;
  }

  &__inner {
    display: flex;
    flex-wrap: wrap;
    margin-block-start: -1 * $_gap;
    margin-inline-start: -1 * $_gap;

    > * {
      flex-basis: calc(#{math.percentage(math.div(1, 3))} - #{$_gap});
      flex-grow: 1;
      min-width: $_item-min-width;
      margin-block-start: $_gap;
      margin-inline-start: $_gap;
    }

    @supports (grid-template-columns: repeat(auto-fill, minmax(250px, 1fr))) {
      /* autoprefixer grid: off */
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax($_item-min-width, 1fr));
      grid-gap: $_gap;
      margin-block-start: 0;
      margin-inline-start: 0;

      > * {
        min-inline-size: none;
        margin-block-start: 0;
        margin-inline-start: 0;
      }
    }
  }
}
