@use 'abstracts/variables';
@use 'abstracts/functions';

@mixin drawer {
  position: fixed;
  inset: 0;
  inline-size: 100%;
  block-size: 100%;
  padding: variables.$header-height functions.padding(x-small)
    functions.padding(x-small);
  overflow-x: hidden;
  overflow-y: auto;
  transform: translateX(100%);
}

.m-drawer {
  @media (max-width: variables.$header-breakpoint - 1) {
    @include drawer;
    color: functions.palette(white);
    background-color: functions.palette(dark-blue);
  }

  @media (min-width: variables.$admin-bar-pos-breakpoint) {
    inset-block-start: var(--admin-bar-height, 0px);
    block-size: calc(100% - var(--admin-bar-height, 0px));
  }

  &--mobile-only {
    display: none;
    @media (max-width: variables.$header-breakpoint - 1) {
      display: block;
    }
  }

  &.is-visible {
    transition: transform 0.3s ease-out;
    transform: translateX(0);
  }
}
