@use 'abstracts/functions';
@use 'abstracts/mixins/base' as base-mixins;
@use 'abstracts/mixins/layout' as layout-mixins;

$_column-break: functions.break(60);

.m-testimonial {
  background-color: functions.palette(light-blue);

  &__inner {
    @include layout-mixins.container-max;
    display: flex;
  }

  &__image--container,
  &__body {
    inline-size: 50%;
  }

  &__image {
    inline-size: 100%;
    block-size: 100%;
    // in the mobile view for browsers that require the ObejctFit polyfill,
    // the div with the image as a background has a default height of 0.
    // this ensures that the image is visible under those circumstances.
    min-block-size: 180px;
    // don't let our image get too tall if someone adds a crazy long quote
    max-block-sizet: 900px;
    object-fit: cover;
    margin: 0;

    &--container {
      display: flex;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include base-mixins.fluid-scale(
      padding,
      functions.padding(medium),
      70px,
      $_column-break
    );
    @include base-mixins.fluid-scale(
      padding-inline-end,
      functions.padding(medium),
      functions.padding(x-large),
      $_column-break
    );
    @include base-mixins.fluid-scale(
      padding-inline-start,
      functions.padding(x-small),
      functions.padding(large),
      $_column-break
    );
    color: functions.palette(dark-blue);
  }

  &__quote {
    font-style: italic;
  }

  &__citation {
    font-style: normal;
    font-weight: functions.font-weight(bold);
  }

  figure {
    margin: 0;
  }

  @media (max-width: $_column-break) {
    &__inner {
      flex-direction: column;
    }

    &__image--container,
    &__body {
      inline-size: 100%;
    }

    &__body {
      padding: functions.padding(medium) functions.padding(x-small);
    }
  }
}
