@use 'abstracts/functions';
@use './base' as base-mixins;
@use 'abstracts/variables';

// Layout mixins
// -------------------

// Containers
// --------------------------------------------------------
@mixin proto-container(
  $max-width: functions.container-width(max),
  $wide-padding: functions.padding(x-large),
  $narrow-padding: functions.padding(x-small)
) {
  @include base-mixins.fluid-scale(
    padding-inline,
    $narrow-padding,
    $wide-padding
  );
  box-sizing: border-box;
  max-inline-size: $max-width;
  margin-inline: auto;
}

// Wide Container
@mixin container-primary {
  @include proto-container;
}

@mixin container-wide {
  @include proto-container(functions.container-width(wide));
}

// Wide Container with reduced padding
@mixin container-secondary {
  @include proto-container(
    functions.container-width(max),
    functions.padding(medium)
  );
}

// The highest-level container for page content. Sets a max-width and centers its children.
@mixin container-max {
  inline-size: 100%;
  max-inline-size: functions.container-width(max);
  margin-inline: auto;
}
