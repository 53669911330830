@use 'abstracts/functions';
@use 'abstracts/mixins/appearance';
@use 'abstracts/mixins/typography';

.m-tease {
  @include appearance.line-separator;
  padding-block-start: functions.padding(small);

  & + & {
    margin-block-start: functions.padding(small);
  }

  &__heading {
    @include typography.heading-quaternary;
    font-weight: functions.font-weight(book);
    color: functions.palette(dark-blue);

    a {
      text-decoration: none;

      &:hover {
        @include typography.link-underlined;
      }
    }
  }

  &__body {
    margin-block-start: 18px;

    a {
      @include typography.link-underlined;
      color: functions.palette(dark-blue);
    }
  }
}
