@use 'abstracts/functions';
@use 'abstracts/mixins/base' as base-mixins;
@use 'abstracts/mixins/layout';

.m-group {
  &[class*='a-bg'] {
    @include base-mixins.fluid-scale(
      padding-block,
      functions.padding(medium),
      functions.padding(x-large)
    );
  }

  > div {
    @include layout.container-primary;
  }

  .m-two-columns {
    padding-inline-start: 0;
    padding-inline-end: 0;
  }

  hr {
    padding-inline-start: 0;
    padding-inline-end: 0;
  }

  &.a-bg-seafoam {
    hr::before {
      border-block-start: 2px functions.palette(white);
    }
  }
}
