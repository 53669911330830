@use 'sass:math';
@use 'abstracts/functions';
@use 'abstracts/mixins/base';
@use 'abstracts/mixins/layout';
@use './mixins' as help-center-mixins;

$_container-width: functions.break(110);
$_sidebar-width: 325px;
$_sidebar-min-width: 225px;
$_main-min-width: 400px;
$_gap-desktop: functions.padding(x-x-large);
$_gap-mobile: functions.padding(medium);
$_breakpoint: functions.help-center(layout-breakpoint);

.m-main-and-sidebar {
  @include base.fluid-scale(
    padding-block-start,
    functions.padding(medium),
    functions.padding(x-x-large)
  );
  @include base.fluid-scale(
    margin-block-start margin-inline-start,
    -1 * $_gap-mobile,
    -1 * $_gap-desktop
  );
  @include base.fluid-scale(
    margin-block-end,
    functions.padding(medium),
    functions.padding(x-x-large)
  );
  display: flex;
  flex-direction: column;

  @include base.respond($_breakpoint) {
    flex-direction: row;
  }

  > * {
    @include base.fluid-scale(
      margin-block-start margin-inline-start,
      $_gap-mobile,
      $_gap-desktop
    );
  }

  &__sidebar {
    flex-basis: math.percentage(math.div($_sidebar-width, $_container-width));
    flex-grow: 1;
    min-width: $_sidebar-min-width;

    .m-main-and-sidebar--hide-sidebar-mobile & {
      @include base.respond($_breakpoint - 1, max) {
        display: none;
      }
    }
  }

  &__main {
    flex-basis: 0;
    flex-grow: 999;

    .m-main-and-sidebar--hide-main-mobile & {
      @include base.respond($_breakpoint - 1, max) {
        display: none;
      }
    }
  }
}
