@use 'abstracts/functions';
@use 'abstracts/mixins/appearance';

.m-input {
  @include appearance.input-base;

  &--light {
    font-weight: functions.font-weight(book);
  }
}
