@use 'abstracts/mixins/typography';
@use 'abstracts/mixins/base';
@use 'abstracts/functions';

.m-quiz {
  color: functions.palette(dark-blue);
  text-align: start;

  &__intro {
    color: functions.palette(white);
    text-align: center;
    background-color: functions.palette(dark-blue);

    * {
      position: relative;
    }

    h3 {
      color: functions.palette(white);
    }

    &::before {
      position: absolute;
      inset: 0;
      display: block;
      content: '';
      background-color: functions.palette(dark-blue);
    }
  }

  &__intro-title {
    @include base.fluid-scale(
      margin-block-end,
      functions.padding(x-small),
      functions.padding(large)
    );
  }

  &__intro-body {
    @include base.fluid-scale(
      padding-block-end,
      functions.padding(x-x-small),
      functions.padding(x-small)
    );
  }

  &__fieldset {
    padding: 0;
    margin: 0;
    border: none;
  }

  &__heading {
    @include base.fluid-scale(font-size, 20px, 24px);
    @include base.fluid-scale(line-height, 22px, 28px);
    max-inline-size: 100%;
    font-weight: functions.font-weight(black);
    color: inherit;

    > * {
      font-size: inherit;
    }

    > * + * {
      padding-block-start: 1em;
    }
  }

  &__body {
    @include base.fluid-scale(font-size, 20px, 24px);
    padding-block-start: functions.padding(small);
    margin-block-start: functions.padding(x-small);
    line-height: inherit;
    border-block-start: 3px solid functions.palette(grey);

    p {
      font-size: inherit;
    }

    > * + * {
      margin-block-start: functions.padding(x-x-small);
    }
  }

  &__radio-group {
    > * + * {
      margin-block-start: functions.padding(x-small);
    }

    > label:not(:first-child) {
      margin-block-start: functions.padding(small);
    }

    p {
      font-size: inherit;
    }
  }

  &__button-group {
    display: flex;
    margin-block-start: functions.padding(medium);

    &--end {
      justify-content: space-between;

      @media (min-width: functions.break(60)) {
        justify-content: flex-end;
      }
    }

    > * + * {
      margin-inline-start: functions.padding(medium);
    }
  }

  &__note {
    display: block;
    margin-block-start: functions.padding(medium);
    font-style: italic;
  }

  &__result {
    @include base.fluid-scale(font-size, 20px, 24px);
    @include base.fluid-scale(line-height, 22px, 30px);
  }

  &--qualify &__body {
    padding-block-start: 0;
    border-block-start-width: 0;

    p,
    li {
      @include typography.copy-primary;
      color: functions.palette(black);
    }

    li {
      @include base.fluid-scale(
        padding-block-start,
        functions.padding(x-x-small),
        functions.padding(x-small)
      );

      &:last-child {
        @include base.fluid-scale(
          padding-block-end,
          functions.padding(x-x-small),
          functions.padding(x-small)
        );
      }
    }
  }

  &--qualify &__radio-group {
    padding-block-start: functions.padding(small);
    margin-block-start: functions.padding(small);
    border-block-start: 3px solid functions.palette(grey);
  }
}
