@use 'abstracts/functions';
@use 'abstracts/mixins/appearance';
@use 'abstracts/variables';

// Base
// --------------------------------------------------------

// Applies a natural box layout model to all the things
*,
*::before,
*::after {
  box-sizing: inherit;
}

// Global
// --------------------------
html {
  // Applies a natural box layout model to all the things
  box-sizing: border-box;
  // Set the base font size here, which will correspond to 1em inside <body>
  font-size: 100%;
}

body {
  // ensure page is always at least 100vh tall; `main` expands to fill space
  display: flex;
  flex-direction: column;
  min-block-size: 100vh;
  color: functions.palette(black);
}

main {
  flex-grow: 1;
}

a {
  @include appearance.focus-default;
  color: inherit;

  &:hover {
    text-decoration: underline;
  }

  &:active {
    // color: $something;
  }

  &:visited {
    // color: $something;
  }
}

button {
  @include appearance.button-unstyled;
  @include appearance.focus-default;
}

.anchor {
  scroll-margin-top: calc(variables.$header-height + 1em);
}
