@use 'abstracts/functions';

.m-pagination {
  padding-block-start: functions.padding(x-small);
  padding-block-end: functions.padding(x-small);
  margin-block-start: functions.padding(small);
  font-size: 14px;
  color: functions.palette(dark-blue);
  border-block-start: 2px solid;
  border-block-end: 2px solid;

  &__list {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__item {
    & + & {
      margin-inline-start: functions.padding(small);
    }
  }

  &__link {
  }

  &__active-page {
    font-weight: functions.font-weight(bold);
    text-decoration: underline;
  }

  &__icon {
    position: relative;
    inset-block-start: 0.2em;
    inline-size: 1.25em;
    block-size: 1.25em;
    fill: currentColor;

    &--rotated {
      transform: rotate(180deg);
    }

    &--disabled {
      cursor: not-allowed;
      opacity: 0.6;
    }
  }
}
