@use 'sass:map';

// Variables
//----------

// Colors
// --------------------------------------------------------
$palette: (
  white: #ffffff,
  black: #212721,
  neutral-10: #f8f8f8,
  neutral-20: #ededed,
  neutral-30: #cbcbcb,
  neutral-80: #333,
  yellow: #fcf9dc,
  light-blue: #ddede5,
  off-white: #f7faf9,
  blue: #05626f,
  dark-blue: #006072,
  seafoam: #f2f8f5,
  magenta: #9b326e,
  turquoise: #6bcbb8,
  dark-turquoise: #39a28d
);

// Responsive breakpoints
// --------------------------------------------------------
$breakpoints: (
  130: 1280px,
  110: 1130px,
  90: 910px,
  80: 850px,
  60: 620px,
  50: 550px,
  40: 420px
);

// Measurements
// --------------------------------------------------------
$container-widths: (
  max: 1280px,
  wide: 1120px
);

$padding-sizes: (
  x-x-large: 100px,
  x-large: 75px,
  large: 50px,
  medium: 40px,
  small: 30px,
  x-small: 20px,
  x-x-small: 10px
);

// Typography
// --------------------------------------------------------
$line-height-base: 1.25;

$font-stack-noto-sans: 'Noto Sans Arabic',
  'Noto Sans Cyrillic',
  'Noto Sans Gurmukhi',
  'Noto Sans Khmer',
  'Noto Sans Lao',
  'Noto Sans Tagalog',
  'Noto Sans Vietnamese',
  'Noto Sans Osmanya',
  'Noto Sans Ethiopic',
  helvetica,
  arial,
  sans-serif;
$font-stack-base: 'Santral', #{$font-stack-noto-sans};
$font-stack-heading: $font-stack-base;
$font-stack-cjk: 'Noto Sans TC', 'Noto Sans SC', 'Noto Sans KR', 'Santral', sans-serif;

$font-weights: (
  book: 400,
  bold: 700,
  black: 900
);

// Appearance
// --------------------------------------------------------

// Cross-component variables
// --------------------------------------------------------
$header-breakpoint: map-get($breakpoints, 90);
$header-height: #{var(--site-header-height, 72px)};
$admin-bar-pos-breakpoint: 600px;

$help-center: (
  color-primary: map.get($palette, blue),
  layout-breakpoint: map.get($breakpoints, 60)
);
