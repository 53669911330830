@use 'abstracts/functions';
@use 'abstracts/mixins/appearance';
@use 'abstracts/mixins/base' as base-mixins;

.m-select {
  position: relative;
  display: inline-block;
  border-block-end: 3px solid functions.palette(dark-blue);

  &__input {
    @include appearance.focus-default;
    @include base-mixins.fluid-scale(
      padding-inline-end,
      functions.padding(medium),
      functions.padding(x-large)
    );
    padding-block-start: var(--select-padding-block-start);
    color: inherit;
    background: none;
    border: 0;
    border-radius: 0;
    appearance: none;

    &--narrow {
      padding-inline-end: functions.padding(small);
    }
  }

  &__icon {
    position: absolute;
    right: 0;
    height: 100%;
    margin-block-start: var(--select-padding-block-start);
    pointer-events: none;
    fill: functions.palette(dark-blue);
  }
}
