@use 'abstracts/functions';
@use 'abstracts/mixins/base' as base;

.m-question {
  &__question {
    @include base.fluid-scale(font-size, 32px, 56px);
    @include base.fluid-scale(margin-block-end, 25px, 40px);
    line-height: 1.25;
    color: functions.palette(dark-blue);
  }

  &__content-wrapper {
    max-width: 870px;
  }

  &__nav {
    @include base.fluid-scale(margin-block-start, 60px, 90px);
  }
}
