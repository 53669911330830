@use 'abstracts/functions';
@use 'abstracts/mixins/base';
@use 'abstracts/mixins/layout';

$_layout-breakpoint: functions.break(50);
$_mobile-padding: functions.padding(x-small);
$_desktop-padding: functions.padding(x-large);
$_border-width: 10px;

.m-full-bleed-hero {
  overflow: hidden;

  &:not(:last-child) {
    margin-block-end: $_border-width;
  }

  &--magenta {
    color: functions.palette(white);
    background-color: functions.palette(magenta);
  }

  &--light-blue {
    color: functions.palette(dark-blue);
    background-color: functions.palette(light-blue);
  }

  &--dark-blue {
    color: functions.palette(white);
    background-color: functions.palette(blue);
  }

  &--yellow {
    color: functions.palette(magenta);
    background-color: functions.palette(yellow);
  }

  &__inner {
    @include layout.container-primary;
    position: relative;
    display: grid;
    block-size: 100%;
    grid-template:
      'image' auto
      'text' auto / 100%;
    align-items: center;

    @media (min-width: $_layout-breakpoint) {
      grid-template:
        'text . image' auto
        / minmax(auto, 650px) 1fr auto;
    }

    @media (max-width: functions.break(130) - 1) {
      padding: 0;
    }
  }

  &__text-block {
    @include base.fluid-scale(
      padding,
      $_mobile-padding * 2,
      $_desktop-padding,
      $_layout-breakpoint
    );
    display: flex;
    flex-direction: column;
    grid-area: text;
    align-items: flex-start;
  }

  &__image-block {
    grid-area: image;
  }

  // IE overflow fix
  &__heading,
  &__body {
    max-inline-size: 100%;
  }

  &__heading br {
    @media (min-width: functions.break(80)) {
      display: block;
    }
  }

  &__body {
    @include base.fluid-scale(font-size, 16px, 24px);
    margin-block-start: 0.75em;
    line-height: 1.25;
  }

  &__link {
    @include base.fluid-scale(margin-block-start, 19px, 30px);
    display: flex;
    background-color: functions.palette(dark-blue);
  }

  &__image {
    display: block;
    width: 100%;
    object-fit: cover;

    @media (min-width: $_layout-breakpoint) {
      block-size: 33.594vw;
      max-block-size: 430px;
    }

    @media (max-width: $_layout-breakpoint - 1) {
      inline-size: 100%;
      max-block-size: 196px;
    }
  }
}
