@use 'abstracts/functions';
@use './base' as base-mixins;
@use 'abstracts/variables';

// Typography mixins
// -----------------------------------
// These should be limited to `font` and other typographic properties only.

// Headings
// --------------------------------------------------------
@mixin proto-heading {
  // font-family: variables.$font-stack-heading;
}

@mixin heading-primary {
  @include proto-heading;
  @include base-mixins.fluid-scale(font-size, 28px, 56px);
  font-weight: functions.font-weight(black);
  line-height: 1.143;
}

@mixin heading-secondary {
  @include proto-heading;
  @include base-mixins.fluid-scale(font-size, 28px, 38px);
  font-weight: functions.font-weight(black);
  line-height: 1.182;
}

@mixin heading-tertiary {
  @include proto-heading;
  @include base-mixins.fluid-scale(font-size, 16px, 22px);
  font-weight: functions.font-weight(bold);
  line-height: 1.375;
  text-transform: uppercase;
}

@mixin heading-quaternary {
  @include proto-heading;
  @include base-mixins.fluid-scale(font-size, 14px, 24px);
  @include base-mixins.fluid-scale(line-height, 18px, 28px);
  font-weight: functions.font-weight(black);
}

// Copy
// --------------------------------------------------------

@mixin proto-copy {
  @include base-mixins.fluid-scale(font-size, 14px, 18px);
  @include base-mixins.fluid-scale(line-height, 18px, 24px);
}

@mixin copy-primary {
  @include proto-copy;
}

@mixin copy-secondary {
  @include base-mixins.fluid-scale(font-size, 12px, 16px);
  @include base-mixins.fluid-scale(line-height, 18px, 22px);
}

@mixin copy-lead {
  @include base-mixins.fluid-scale(font-size, 14px, 24px);
  @include base-mixins.fluid-scale(line-height, 18px, 30px);
}

// Links
// --------------------------------------------------------

@mixin link-underlined {
  text-decoration: underline;
}

// Lists
// --------------------------------------------------------
@mixin list-unstyled {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

@mixin list-unordered {
  padding: 0;
  margin: 0 0 0 1em;
}

@mixin list-ordered {
  padding: 0;
  margin: 0 0 0 1em;
}

// Misc
// --------------------------------------------------------
@mixin hide-text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
}
