@use 'abstracts/functions';
@use 'abstracts/mixins/base' as base-mixins;
@use 'abstracts/mixins/layout' as layout;
@use 'abstracts/mixins/typography' as typography;
@use 'abstracts/mixins/appearance';

$breakpoint: functions.break(60);

/*
** begin legacy styles for deprecated version of CTA block
*/
.wp-block-cic-pfml-blocks-cta {
  .m-cta {
    &__text {
      font-weight: functions.font-weight(book);
      @include base-mixins.fluid-scale(font-size, 28px, 44px);
    }

    &__inner {
      > *:not(:last-child) {
        @include base-mixins.fluid-scale(
          padding-block-end,
          functions.padding(x-x-small),
          functions.padding(x-small)
        );
      }

      > *:first-child {
        @include base-mixins.fluid-scale(
          padding-block-end,
          functions.padding(x-small),
          functions.padding(medium)
        );
      }

      > *:last-child {
        @include base-mixins.fluid-scale(
          margin-block-start,
          functions.padding(x-x-small),
          functions.padding(x-small)
        );
      }
    }
  }
}
/*
** end legacy styles
*/

.m-cta {
  @media (min-width: $breakpoint) {
    text-align: center;
  }

  /*
  ** begin legacy styles for deprecated version of CTA block
  */
  &__magenta {
    display: block;
    color: functions.palette(white);
    background-color: functions.palette(magenta);
  }

  &__white {
    display: block;
    color: functions.palette(dark-blue);
  }
  /*
  ** end legacy styles
  */

  &--white {
    color: functions.palette(dark-blue);

    .a-bg-white + &,
    .m-cta--white + &,
    .m-two-columns + & {
      &::before {
        @include layout.container-primary;
        @include appearance.line-separator;
      }
    }
  }

  &--magenta {
    color: functions.palette(white);
    background-color: functions.palette(magenta);
  }

  &--turquoise {
    color: functions.palette(dark-blue);
    background-color: functions.palette(turquoise);
  }

  &--off-white {
    color: functions.palette(dark-blue);
    background-color: functions.palette(off-white);
  }

  &__inner {
    @include base-mixins.fluid-scale(
      padding-block,
      functions.padding(medium),
      functions.padding(x-large)
    );
    @include layout.container-primary;

    // inline links
    p > a:not(.m-button) {
      color: inherit;
    }

    p {
      @media screen and (min-width: $breakpoint) {
        padding: 0;
      }
    }

    a {
      white-space: nowrap;
    }

    &--one-column {
      div {
        @include base-mixins.fluid-scale(
          margin-block-start,
          functions.padding(x-x-small),
          functions.padding(x-small)
        );
      }

      .m-cta__text {
        @include base-mixins.fluid-scale(font-size, 28px, 44px);
        padding-block-end: functions.padding(x-small);
        font-weight: functions.font-weight(book);
      }
    }

    &--two-column {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: functions.font-weight(bold);
      @media screen and (max-width: $breakpoint - 1px) {
        display: block;
      }

      .m-cta__text {
        font-weight: functions.font-weight(bold);
        text-align: left;
        @include base-mixins.fluid-scale(font-size, 16px, 24px);
        @include base-mixins.fluid-scale(
          padding-block-end,
          functions.padding(x-small),
          functions.padding(medium),
          functions.break(40),
          $breakpoint
        );

        @media screen and (min-width: $breakpoint) {
          padding-block-end: 0;
        }

        @include base-mixins.fluid-scale(
          padding-inline-end,
          functions.padding(medium) - functions.padding(x-x-small),
          functions.padding(x-large) - functions.padding(x-small),
          $breakpoint
        );
      }

      .m-cta__content {
        @include base-mixins.fluid-scale(
          margin-block-start,
          functions.padding(x-x-small),
          functions.padding(x-small),
          functions.break(40),
          $breakpoint
        );

        @media screen and (min-width: $breakpoint) {
          flex-shrink: 0;
          margin-block-start: 0;
        }

        @include base-mixins.fluid-scale(
          margin-inline-start,
          functions.padding(x-x-small),
          functions.padding(x-small),
          $breakpoint
        );

        @media screen and (max-width: $breakpoint) {
          margin-inline-start: 0;
        }
      }
    }
  }

  &__text {
    @include typography.heading-secondary;

    > a:not([class]) {
      color: inherit;
    }
  }

  .m-button {
    position: relative;
    inset-block-start: -3px;
  }
}
