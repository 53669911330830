@use 'abstracts/functions';
@use 'abstracts/mixins/base' as base-mixins;

.m-video {
  position: relative;

  inline-size: 100%;
  max-inline-size: 100%;
  padding-block-start: 56.25%;
  border: 1px solid functions.palette(neutral-30);

  iframe {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }

  &--is-hidden {
    display: none;
  }

  &__preview {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }

  &__thumbnail {
    max-inline-size: 100%;
    object-fit: cover;
  }

  &__play-button {
    position: absolute;
    inset-block-start: 50%;
    inset-inline-start: 50%;
    color: functions.palette(dark-blue);
    cursor: pointer;
    transform: translate(-50%, -50%);

    &:hover,
    &:focus {
      color: functions.palette(dark-turquoise);
      outline: none;
    }
  }

  &__play-icon {
    @include base-mixins.fluid-scale(width height, 64px, 128px);
  }

  &__play-icon {
    &--circle {
      opacity: 0.9;
      fill: currentColor;
    }
  }
}
