@use 'abstracts/functions';
@use 'abstracts/mixins/base';
@use 'abstracts/mixins/layout';
@use 'abstracts/mixins/typography';
@use './mixins' as help-center-mixins;

.m-help-center-footer {
  background-color: #f2f8f5;

  &__inner {
    @include layout.container-primary;
    @include base.fluid-scale(padding-block, 40px, 90px);
    display: flex;
    align-items: center;
    justify-content: center;

    @include help-center-mixins.on-mobile {
      align-items: flex-start;
    }

    > * + * {
      @include base.fluid-scale(margin-inline-start, 40px, 100px);
      @include help-center-mixins.on-mobile {
        margin-inline-start: 0;
        margin-inline-end: 10px;
      }
    }
  }

  &__icon {
    @include base.fluid-scale(inline-size, 73.86px, 205.38px);
    @include base.fluid-scale(block-size, 63.55px, 176.73px);
    flex-shrink: 0;
    fill: #6fc7b6;

    @include help-center-mixins.on-mobile {
      order: 1;
    }
  }

  &__content {
  }

  &__heading {
    margin-block-end: 0.5em;
    font-size: 24px;
    font-weight: functions.font-weight(book);
    color: functions.help-center(color-primary);
  }
}
