@use 'abstracts/functions';

.m-hero-group {
  background-color: functions.palette(neutral-10);

  &__inner {
    display: flex;
    flex-wrap: wrap;
    margin-inline-start: auto;
    margin-inline-end: auto;
    max-inline-size: functions.container-width(max);
  }
}
