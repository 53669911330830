@use 'abstracts/variables';
@use 'abstracts/functions';
@use 'abstracts/mixins/typography';
@use 'abstracts/mixins/base' as base-mixins;

// Typography
// --------------------------------------------------------

body {
  @include base-mixins.fluid-scale(font-size, 14px, 18px);
  @include base-mixins.fluid-scale(line-height, 18px, 24px);
  font-family: variables.$font-stack-base;
  font-weight: functions.font-weight(book);
}

// Block-level elements
// --------------------------
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;

  br {
    display: none;

    @media (min-width: functions.break(60)) {
      display: block;
    }
  }
}

h1,
.h1 {
  @include typography.heading-primary;
}

h2,
.h2 {
  @include typography.heading-primary;
}

h3,
.h3 {
  @include typography.heading-secondary;
}

h4,
.h4 {
  @include typography.heading-tertiary;
}

p {
  @include typography.copy-primary;
  margin: 0;
}

ul {
  @include typography.list-unordered;
  list-style: none;
}

ol {
  @include typography.list-ordered;
}

.m-footer {
  p,
  ul,
  ol {
    @include typography.copy-secondary;
  }
}

.m-subnav {
  @include base-mixins.fluid-scale(font-size, 16px, 20px);

  p,
  ul,
  ol {
    @include base-mixins.fluid-scale(font-size, 16px, 20px);
  }
}

hr {
}

// Inline elements
// --------------------------

b,
strong {
}
