@use 'abstracts/functions';
@use 'abstracts/mixins/base';

.m-page-content {
  > .a-bg-white {
    &:first-child,
    + .a-bg-white {
      @include base.fluid-scale(
        padding-block-start,
        functions.padding(medium),
        functions.padding(x-x-large)
      );
    }

    &:last-child {
      @include base.fluid-scale(
        padding-block-end,
        functions.padding(medium),
        functions.padding(x-x-large)
      );
    }
  }
}
