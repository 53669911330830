/*!

	Styles for Paid Family Medical Leave
	By Cast Iron Coding (office@castironcoding.com)

*/
// Vendors
@forward '~modern-css-reset';

// Fonts
@forward 'abstracts/fonts';

// Base styles
@forward 'base/base';
@forward 'base/typography';

// Modules
//
// Self-contained, reuseable blocks of markup.
// Indicated by the class prefix `.m-`.
@forward 'modules/index';

// Utility
//
// Utility classes for common typography, appearance, layout, and state styles.
// These are designed to be scoped to distinct concerns in styling,
// such as the `font-size` and `font-family` of a common heading presentation,
// or the `width` and `margin` of a recurring container style.
// Utility classes are indicated by a prefix:
// * `t-` - typography
// * `a-` - appearance
// * `l-` - layout
// * `is-`/`has-` - state
@forward 'utility/typography';
@forward 'utility/appearance';
@forward 'utility/layout';
@forward 'utility/state';

// Z-Stack
@forward 'z-stack';
