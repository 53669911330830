@use 'abstracts/functions';
@use 'abstracts/variables';

$_button-count: 1;
$_button-size: 20px;
$_button-padding: 12px;

.m-search {
  position: relative;
  inline-size: 100%;
  font-size: 16px;

  &__input {
    padding-block-end: 2px;
    padding-inline-end: $_button-count * $_button-size + $_button-padding * 2;
    font-size: inherit;
    color: inherit;
    background-color: transparent;
    border: none;
    transition: color 0.2s, border 0.2s;
    inline-size: 100%;
    appearance: none;

    &::placeholder {
      font-style: italic;
      color: inherit;
    }

    &:focus {
      outline: 0;
    }
  }

  &__button-group {
    position: absolute;
    inset-block-start: 0;
    inset-inline-end: 0;
    display: flex;
    align-items: center;
  }

  &__button {
    cursor: pointer;
    transition: color 0.2s;

    &:hover {
      color: functions.palette(dark-turquoise);
    }

    & + & {
      margin-inline-start: $_button-padding;
    }
  }

  &__icon {
    inline-size: $_button-size;
    block-size: $_button-size;
    fill: currentColor;
  }

  // Mobile & desktop styles are very different,
  // so keep styles scoped by media query together
  @media (max-width: variables.$header-breakpoint - 1) {
    &__input {
      font-weight: functions.font-weight(book);
    }
  }

  @media (min-width: variables.$header-breakpoint) {
    max-inline-size: 500px;
    margin-inline-end: -15px;
    color: functions.palette(dark-blue);

    &__input {
      border-block-end: 1px solid functions.palette(dark-blue);

      &:focus-visible {
        border-bottom-color: functions.palette(
          dark-turquoise
        ); // postcss-logical doesn't transform this property correctly
      }
    }
  }
}
