@use 'abstracts/functions';
@use 'abstracts/mixins/appearance';

.m-search-results {
  &__section {
    @include appearance.line-separator;
    padding-block: functions.padding(x-small);
    margin-block-start: functions.padding(x-small);
  }
}
