@use 'sass:math';
@use 'abstracts/functions';
@use 'abstracts/mixins/base';
@use 'abstracts/mixins/layout';

$_layout-breakpoint: functions.break(50);
$_mobile-padding: functions.padding(x-small);
$_desktop-padding: functions.padding(x-large);
$_border-width: 10px;
$_mobile-full-bleed-padding: functions.padding(x-small) * 2;

.m-hero {
  overflow: hidden;

  &:not(:last-child) {
    margin-block-end: $_border-width;
  }

  &--magenta {
    color: functions.palette(white);
    background-color: functions.palette(magenta);
  }

  &--light-blue {
    color: functions.palette(dark-blue);
    background-color: functions.palette(light-blue);
  }

  &--dark-blue {
    color: functions.palette(white);
    background-color: functions.palette(blue);
  }

  &--yellow {
    color: functions.palette(magenta);
    background-color: functions.palette(yellow);
  }

  // if nested in .m-hero-group
  &--full {
    flex-basis: 100%;
  }

  &--half {
    flex-basis: 100%;

    @media (min-width: functions.break(80)) {
      flex-basis: calc(50% - #{math.div($_border-width, 2)});

      &:nth-child(odd) {
        margin-inline-end: $_border-width;
      }
    }
  }

  &__inner {
    @include layout.container-primary;
    @include base.fluid-scale(
      padding-block,
      $_mobile-padding,
      $_desktop-padding,
      $_layout-breakpoint
    );
    position: relative;
    display: grid;
    block-size: 100%;

    .m-hero--half & {
      @include base.fluid-scale(
        row-gap,
        functions.padding(x-small),
        functions.padding(large)
      );
      grid-template:
        'text' auto
        'image' auto / 100%;

      @media (min-width: functions.break(80)) {
        grid-template:
          'image' auto
          'text' auto / 100%;
      }
    }

    .m-hero--full & {
      grid-template:
        'image' auto
        'text' auto / 100%;

      @media (min-width: $_layout-breakpoint) {
        grid-template:
          'text . image' auto
          / minmax(auto, 650px) 1fr auto;
        grid-gap: functions.padding(x-small);
      }
    }

    .m-hero--mobile-full-bleed & {
      @media (max-width: $_layout-breakpoint - 1) {
        padding-block-end: $_mobile-full-bleed-padding;
      }
    }
  }

  &__text-block {
    display: flex;
    flex-direction: column;
    grid-area: text;
    align-items: flex-start;

    .m-hero--half & {
      justify-content: flex-end;
    }
  }

  &__image-block {
    grid-area: image;
    inline-size: calc(100% + #{$_mobile-padding});
    @include base.fluid-scale(
      margin-block-start margin-block-end margin-inline-end,
      -1 * $_mobile-padding,
      -1 * $_desktop-padding,
      $_layout-breakpoint
    );

    @media (min-width: $_layout-breakpoint) {
      inline-size: calc(
        100% + #{math.div($_mobile-padding, $_layout-breakpoint) * 100}vw
      );
    }

    @media (min-width: functions.break(130)) {
      inline-size: calc(100% + #{$_desktop-padding});
    }

    @media (max-width: $_layout-breakpoint - 1) {
      margin-block-end: 0px;
    }

    &--mobile-full-bleed {
      @media (max-width: $_layout-breakpoint - 1) {
        inline-size: calc(100% + #{2 * $_mobile-padding});
        margin: -1 * $_mobile-padding -1 * $_mobile-padding $_mobile-full-bleed-padding;
      }
    }
  }

  // IE overflow fix
  &__heading,
  &__body {
    max-inline-size: 100%;
  }

  &__heading br {
    @media (min-width: functions.break(80)) {
      display: block;
    }
  }

  &__body {
    @include base.fluid-scale(font-size, 16px, 24px);
    margin-block-start: 0.75em;
    line-height: 1.25;
  }

  &__link {
    @include base.fluid-scale(margin-block-start, 19px, 30px);
    display: flex;
    background-color: functions.palette(dark-blue);
  }

  &__image {
    @include base.fluid-scale(max-height, 160px, 330px);
    inline-size: auto;
    block-size: auto;
    margin-inline-start: auto;

    .m-hero--full & {
      @media (min-width: $_layout-breakpoint) {
        max-block-size: none;
      }
    }

    .m-hero__image-block--hide-image &--desktop {
      @media (max-width: $_layout-breakpoint - 1) {
        display: none;
      }
    }

    &--mobile-full-bleed {
      display: none;

      @media (max-width: $_layout-breakpoint - 1) {
        display: block;
        inline-size: 100%;
        max-block-size: 196px;
        object-fit: cover;
      }
    }
  }
}
