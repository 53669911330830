@use 'abstracts/mixins/typography';
@use 'abstracts/mixins/layout';
@use 'abstracts/mixins/appearance';
@use 'abstracts/mixins/base' as base-mixins;
@use 'abstracts/functions';

$_heading-max-width: 900px;

.m-content-rte {
  // all RTE text elements
  > :not([class*='m-']) {
    @include layout.container-primary;
  }

  // firs child and a text element
  > :first-child:not([class*='m-']) {
    @include base-mixins.fluid-scale(
      padding-block-start,
      functions.padding(medium),
      functions.padding(x-x-large)
    );
  }

  // text element + text element
  > :not([class]) + :not([class]) {
    margin-block-start: 1em;
  }

  // last child that's a text element
  > :not([class]):last-child {
    @include base-mixins.fluid-scale(
      margin-block-end,
      functions.padding(medium),
      functions.padding(x-x-large)
    );
  }

  // text element followed by custom block
  // custom block followed by a text element
  > :not([class]) + [class*='m-'],
  > [class*='m-'] + :not([class*='m-']) {
    @include base-mixins.fluid-scale(
      margin-block-start,
      functions.padding(medium),
      functions.padding(x-x-large)
    );
  }

  h3,
  .has-cic-large-font-size {
    @include base-mixins.fluid-scale(
      padding-block-end,
      functions.padding(x-x-small),
      functions.padding(small)
    );
  }

  h2,
  h3,
  h4,
  strong {
    color: functions.palette(dark-blue);
  }

  h3 > strong {
    font-weight: functions.font-weight(black);
  }

  h4 {
    padding-block-end: functions.padding(x-x-small);
  }

  h5,
  h6 {
    @include typography.heading-quaternary;
  }

  a:not(.m-button) {
    color: functions.palette(dark-blue);
  }

  hr {
    @include layout.container-primary;
    @include base-mixins.fluid-scale(
      margin-block,
      functions.padding(medium),
      functions.padding(x-x-large)
    );

    border: 0;

    &::before {
      @include appearance.line-separator;
    }
  }

  p,
  li {
    @include base-mixins.fluid-scale(
      padding-block-end,
      functions.padding(x-x-small),
      functions.padding(x-small)
    );
  }

  p {
    + h2,
    + h3 {
      @include base-mixins.fluid-scale(
        margin-block-start,
        functions.padding(x-small),
        functions.padding(x-large)
      );
    }
  }

  ul li {
    &::before {
      display: inline-block;
      inline-size: 1em;
      margin-inline-start: -1em;
      color: functions.palette(dark-blue);
      content: '\2022';
    }
  }

  .has-cic-large-font-size {
    @include typography.copy-lead;
    color: functions.palette(dark-blue);
  }

  // Override Wordpress' center align classes
  .has-text-align {
    &-center {
      text-align: start;
    }

    @include base-mixins.respond(functions.break(60)) {
      &-center,
      [dir] &-center {
        text-align: center;
      }
    }
  }

  img {
    block-size: auto;
  }
}
