@use 'abstracts/variables';

// Global z-index stack
// -----------------------------------
.m-dialog.is-open {
  z-index: 100;
}

.has-scroll-lock.dialog-is-open::after {
  z-index: 99;
}

.m-header__hamburger,
.m-header__logo {
  z-index: 90;
}

.m-subnav.is-visible {
  @media (max-width: variables.$header-breakpoint - 1) {
    z-index: 80;
  }
}

.m-notification {
  z-index: 20;
}

.m-header {
  z-index: 10;
}
