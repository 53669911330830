@use 'abstracts/functions';
@use 'abstracts/mixins/appearance';

.m-button {
  @include appearance.button-base;

  &--dark-blue {
    background-color: functions.palette(dark-blue);

    &[disabled],
    &.m-button--is-disabled {
      opacity: 0.4;

      &:hover,
      &:focus-visible {
        background-color: functions.palette(dark-blue);
      }
    }
  }

  &--magenta {
    background-color: functions.palette(magenta);

    &[disabled],
    &.m-button--is-disabled {
      opacity: 0.4;

      &:hover,
      &:focus-visible {
        background-color: functions.palette(magenta);
      }
    }
  }

  &--white {
    color: functions.palette(dark-blue);
    background-color: functions.palette(white);

    &:hover {
      color: functions.palette(white);
    }

    &[disabled],
    &.m-button--is-disabled {
      opacity: 0.4;

      &:hover,
      &:focus-visible {
        color: functions.palette(dark-blue);
        background-color: functions.palette(white);
      }
    }
  }

  &--white-magenta {
    color: functions.palette(magenta);
    background-color: functions.palette(white);

    &:hover {
      color: functions.palette(white);
    }

    &[disabled],
    &.m-button--is-disabled {
      opacity: 0.4;

      &:hover,
      &:focus-visible {
        color: functions.palette(magenta);
        background-color: functions.palette(white);
      }
    }
  }

  &--link {
    @include appearance.focus-default;
    color: functions.palette(dark-blue);
    text-align: start;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  &--select {
    appearance: none;
    border: none;
  }

  &--skip-content {
    @include appearance.box-shadow;
    position: fixed;
    inset-block-start: var(--admin-bar-height, 0px);
    inset-inline-start: 0;
    transform: translateX(-100%);

    &:focus-visible {
      transform: translateX(0);
    }
  }

  &__icon {
    @include appearance.proto-icon;
    position: relative;
    inset-block-start: -0.06em;

    &--pre {
      margin-inline-end: 5px;
    }

    &--post {
      margin-inline-start: 5px;

      .a-hidden + & {
        margin-inline-start: 0;
      }
    }
  }
}

a.m-button {
  &:hover {
    color: functions.palette(white);
    text-decoration: none;
  }

  &--is-disabled {
    pointer-events: none;
  }
}
