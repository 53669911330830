@use 'abstracts/functions';
@use 'abstracts/mixins/base';
@use 'abstracts/mixins/layout';
@use './mixins' as help-center-mixins;

.m-primary-category-nav {
  @include base.fluid-scale(
    margin-block-start,
    functions.padding(x-large),
    functions.padding(x-x-large)
  );
  @include help-center-mixins.show-desktop;
  color: functions.help-center(color-primary);

  &__list {
    display: flex;
    justify-content: center;
    border-block-end: 1px solid;
  }

  &__item {
    & + & {
      @include base.fluid-scale(
        margin-inline-start,
        functions.padding(medium),
        functions.padding(x-large)
      );
    }
  }

  &__link {
    position: relative;
    display: block;
    padding: 3px;
    text-decoration: none;
    border-block-end: 4px solid transparent;
    transition: border-block-end 0.2s, font-weight 0.2s;

    &--active,
    &:hover,
    &:focus-visible {
      font-weight: functions.font-weight(black);
      text-decoration: none;
      border-block-end: 4px solid currentColor;
      outline: none;
    }
  }

  &__link-text {
    position: absolute;
    inline-size: 100%;
  }

  // hidden copy of link text to prevent width of link changing on hover
  &__link-placeholder {
    font-weight: functions.font-weight(black);
    opacity: 0;
  }
}
